import axiosInstance from "./axios";

const FormatDate = (dateString) => {
  const dateRetour = dateString.split("/");
  return dateRetour[2] + "-" + dateRetour[1] + "-" + dateRetour[0];
};

//GETCHAMBRE
export const getChambre = async () => {
  return await axiosInstance
    .get("/hotel/getChambre")
    .then((listChambre) => {
      return listChambre.data;
    })
    .catch((err) => {
      console.log(err);
    });
};
//GET_HOTEL_TYPE
export const getHotelType = async () => {
  return await axiosInstance
    .get("/hotel/getHotelType")
    .then((listeHotelType) => {
      return listeHotelType.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

// GET_CHAMBRE_DISPO_FILTER
export const getChambreDispoFilter = async (
  checkIn,
  checkOut,
  adulte,
  enfant
) => {
  return await axiosInstance
    .get("/hotel/getChambreDispoFilter", {
      params: {
        checkIn: FormatDate(checkIn),
        checkOut: FormatDate(checkOut),
        adulte: adulte,
        enfant: enfant,
      },
    })
    .then((listeChambre) => {
      // console.log(listeChambre.data)
      return listeChambre.data;
    })
    .catch((err) => {
      console.log(err);
    });
};
// GET_CHAMBRE_PAR_TYPE
export const getChambreParType = async (typeHotel) => {
  return await axiosInstance
    .get("/hotel/getChambreParType", {
      params: {
        type: typeHotel,
      },
    })
    .then((listHotel) => {
      return listHotel.data;
    })
    .catch((err) => {
      console.log(err);
    });
};
//Get one chambre
export const getOneChambre = async (id) => {
  return await axiosInstance
    .get("/hotel/getOneChambre", {
      params: {
        id: id,
      },
    })
    .then((listChambre) => {
      return listChambre.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

// Modifier chambre
export const modifierChambre = async (
  id,
  intitule,
  prix,
  categorie,
  hotelType
) => {
  return await axiosInstance
    .put(`/hotel/modifierChambre/` + id, {
      intitule: intitule,
      prix: prix,
      idCategorie: categorie,
      idHotelType: hotelType,
    })
    .then(() => {
      console.log("Chambre " + intitule + " modifié");
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getAllCategorie = async () => {
  return await axiosInstance
    .get("/hotel/getAllCategorie")
    .then((listeCategorie) => {
      return listeCategorie.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

//Supprimer Chambre
export const supprimer = async (id) => {
  await axiosInstance
    .delete("/hotel/supprimer/" + id)
    .then(() => {
      window.alert("Chambre " + id + " supprimé");
    })
    .catch((err) => {
      console.log(err);
    });
};

// Bloquer une chambre
export const blockRoom = async (
  idChambre,
  isBlocked,
  permanent,
  startBlock,
  endBlock
) => {
  const response = await axiosInstance.put("/hotel/block", {
    idChambre,
    isBlocked,
    permanent,
    startBlock,
    endBlock,
  });
  return response.data;
};

export const unBlockRoom = async (idChambre) => {
  const response = await axiosInstance.put("/hotel/unblock", {
    idChambre,
  });
  return response.data;
};
