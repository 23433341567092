import { Flex, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import "./index.css";
import Card from "components/card/Card";
import { getAvis, suppAvis } from "../../../../api/Avis";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IconButton, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure, Button } from "@chakra-ui/react";
import { FaTrash } from "react-icons/fa";

function DeleteModal({ isOpen, onClose, onDelete, avisId }) {
  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Suppression</ModalHeader>
        <ModalCloseButton />
        <ModalBody>Voulez vous vraiment supprimer cette avis ?</ModalBody>
        <ModalFooter>
          <Button onClick={onClose} mr={4} variant="ghost">
            Annuler
          </Button>
          <Button
            colorScheme="red"
            onClick={() => {
              if (!avisId) {
                return;
              }
              onDelete(avisId);
              onClose();
            }}
          >
            Supprimer
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default function ColumnsTable() {
  const [avis, setListAvis] = useState([]);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [avisId, setAvisId] = useState();

  useEffect(() => {
    getAvis()
      .then((listeAvis) => {
        setListAvis(listeAvis);
      })
      .catch(() => {
        toast.error("Une erreur s'est produite.", {
          hideProgressBar: true,
          autoClose: 3000,
        });
      });
  }, []);

  async function deleteAvis(id) {
    toast.promise(
      async () => {
        await suppAvis(id);
        const newAvis = avis.filter((el) => el._id !== id);
        setListAvis(newAvis);
      },
      {
        error: "Échec de suppression",
        pending: "Suppression en cours",
        success: "Suppression réussie",
      },
      {
        hideProgressBar: true,
        autoClose: 3000,
      }
    );
  }
  return (
    <>
      <DeleteModal onDelete={deleteAvis} avisId={avisId} isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
      <Card direction="column" w="100%" px="0px" className="tableAvis">
        <Flex px="25px" justify="space-between" mb="10px" align="center">
          <Text color={textColor} fontSize="22px" fontWeight="700" lineHeight="100%">
            Liste Avis
          </Text>
        </Flex>
        <Table variant="striped" color="gray.500" mb="24px">
          <Thead>
            <Tr>
              <Th pe="150px" borderColor={borderColor}>
                Nom
                <Flex justify="space-between" align="center" fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
                  {" "}
                </Flex>
              </Th>
              <Th pe="80px" borderColor={borderColor}>
                Prenom
                <Flex justify="space-between" align="center" fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
                  {" "}
                </Flex>
              </Th>
              <Th pe="10px" borderColor={borderColor}>
                Email
                <Flex justify="space-between" align="center" fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
                  {" "}
                </Flex>
              </Th>
              <Th pe="10px" borderColor={borderColor}>
                Numéro
                <Flex justify="space-between" align="center" fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
                  {" "}
                </Flex>
              </Th>
              <Th pe="10px" borderColor={borderColor}>
                Sujet
                <Flex justify="space-between" align="center" fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
                  {" "}
                </Flex>
              </Th>
              <Th pe="200px" borderColor={borderColor}>
                Message
                <Flex justify="space-between" align="center" fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
                  {" "}
                </Flex>
              </Th>
              <Th pe="100px" borderColor={borderColor}>
                Date
                <Flex justify="space-between" align="center" fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
                  {" "}
                </Flex>
              </Th>
              <Th pe="30px" borderColor={borderColor}>
                <Flex justify="space-between" align="center" fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
                  {" "}
                </Flex>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {avis.length > 0 &&
              avis.map((uneAvis) => {
                const [annee, mois, jour] = uneAvis.dateCheck.split("T")[0].split("-");
                const dateCheck = [jour, mois, annee].join("-");
                return (
                  <Tr key={uneAvis._id}>
                    <Td fontSize={{ sm: "14px" }} maxH="30px !important" py="8px" minW={{ sm: "150px", md: "200px", lg: "auto" }} borderColor="transparent">
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {" "}
                        {uneAvis.nom}{" "}
                      </Text>
                    </Td>
                    <Td fontSize={{ sm: "14px" }} maxH="30px !important" py="8px" minW={{ sm: "150px", md: "200px", lg: "auto" }} borderColor="transparent">
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {" "}
                        {uneAvis.prenom}{" "}
                      </Text>
                    </Td>
                    <Td fontSize={{ sm: "14px" }} maxH="30px !important" py="8px" minW={{ sm: "150px", md: "200px", lg: "auto" }} borderColor="transparent">
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {" "}
                        {uneAvis.email}{" "}
                      </Text>
                    </Td>
                    <Td fontSize={{ sm: "14px" }} maxH="30px !important" py="8px" minW={{ sm: "150px", md: "200px", lg: "auto" }} borderColor="transparent">
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {" "}
                        {uneAvis.numero}{" "}
                      </Text>
                    </Td>
                    <Td fontSize={{ sm: "14px" }} maxH="30px !important" py="8px" minW={{ sm: "150px", md: "200px", lg: "auto" }} borderColor="transparent">
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {" "}
                        {uneAvis.sujet}{" "}
                      </Text>
                    </Td>
                    <Td fontSize={{ sm: "14px" }} maxH="30px !important" py="8px" minW={{ sm: "150px", md: "200px", lg: "auto" }} borderColor="transparent">
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {" "}
                        {uneAvis.message}{" "}
                      </Text>
                    </Td>
                    <Td fontSize={{ sm: "14px" }} maxH="30px !important" py="8px" minW={{ sm: "150px", md: "200px", lg: "auto" }} borderColor="transparent">
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {" "}
                        {dateCheck}{" "}
                      </Text>
                    </Td>
                    <Td fontSize={{ sm: "14px" }} maxH="30px !important" py="4px" minW={{ sm: "100px", md: "100px", lg: "75px" }} borderColor="transparent">
                      <IconButton
                        onClick={() => {
                          onOpen();
                          setAvisId(uneAvis._id);
                        }}
                        title="Supprimer"
                        colorScheme="red"
                        variant="ghost"
                        icon={<FaTrash />}
                      />
                    </Td>
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </Card>
    </>
  );
}
