import React from "react";
import "assets/css/App.css";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import AuthLayout from "layouts/auth";
import "bootstrap/dist/css/bootstrap.css";
import AdminLayout from "layouts/admin";
import { ChakraProvider } from "@chakra-ui/react";
import Facture from "layouts/facture";
import FactureLocation from "layouts/factureLocation";
import { createRoot } from "react-dom/client";
import {ToastContainer} from "react-toastify"

const root = createRoot(document.getElementById("root"));

root.render(
  <ChakraProvider>
    {/* <ThemeEditorProvider> */}
    <ToastContainer />
    <HashRouter>
      <Switch>
        <Route path={`/facture/:id`} component={Facture} />
        <Route path={`/factureLocation/:id`} component={FactureLocation} />
        <Route path={`/auth`} component={AuthLayout} />
        <Route path={`/admin`} component={AdminLayout} />
        <Redirect from="/" to="/admin" />
      </Switch>
    </HashRouter>
    {/* </ThemeEditorProvider> */}
  </ChakraProvider>
);
