import {
  Box,
  SimpleGrid,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { getVoiture } from '../../../api/Voiture'
import { LocationVoiture, getLocationVoiture } from '../../../api/location'
import './index.css'
import { Modal } from 'react-bootstrap'
import { dateSendBack } from '../../../fonction/aammjj'
export default function UserReports() {
  const [voiture, getListeVoiture] = useState([]);
  useEffect(() => {
    getVoiture().then((listeVoiture) => {
      getListeVoiture(listeVoiture)
    })
  }, [voiture.length]);


  const [selectedValue, setSelectedValue] = useState('')
  useEffect(() => {
    liste()
  }, [selectedValue])
  const liste = () => {
    const choixListe = document.getElementById("choixListe")?.value
    setSelectedValue(choixListe)
    if (selectedValue === "chambre") {
      window.location.href = "/#/admin/reserver"
    }
    else if (selectedValue === "location") {
      window.location.href = "/#/admin/formulaireLocation"
    }
  }

  // LOCATION
  const [bChoix, setBchoix] = useState(true)

  const laDate = (date) => {
    const tabDate = date.split('/')
    return tabDate[1] + '/' + tabDate[0] + '/' + tabDate[2]
  }
  const dateFormat = (date) => {
    const laDate = new Date(date).toLocaleDateString()
    const tabDate = laDate.split('/')
    return tabDate[1] + '/' + tabDate[0] + '/' + tabDate[2]
  }
  const [proposVoiture, setProposVoiture] = useState({
    marque: '',
    idVoiture: '',
    prix: '',
    start: new Date(),
    end: new Date()
  })

  const [listeVoiture, setListeVoiture] = useState([])
  useEffect(() => {
    getVoiture().then((voitureListe) => {
      setListeVoiture(voitureListe)
    })
  }, [])

  const [voitureOccupe, setVoitureOccupe] = useState(false)
  const today = new Date(Date.now())
  const [date, setDate] = useState({
    debut: today.toLocaleDateString(),
    term: today.toLocaleDateString()
  })
  useEffect(() => {
    getLocationVoiture().then((locationListe) => {
      const listeMemeMarque = locationListe.filter(location => location.marque === proposVoiture.marque
        && location.etat === 0 && location.etatPaie === 0)
      if (listeMemeMarque.length === 0) {
        const boutonDispo = document.getElementById('bDispo')
        boutonDispo?.addEventListener('click', (e) => {
          e.preventDefault()
            setBchoix(false)
        })
      }
      else {
        listeMemeMarque.map((filtreDate) => {
          const dateBdStart = new Date(dateFormat(filtreDate?.start))
          const dateBdEnd = new Date(dateFormat(filtreDate?.end))
          const dateInsertStart = new Date(laDate(proposVoiture.start))
          const dateInsertEnd = new Date(laDate(proposVoiture.end))

          if ((dateInsertStart <= dateBdStart && dateInsertEnd >= dateBdStart)
            || (dateInsertStart <= dateBdEnd && dateInsertEnd >= dateBdEnd)
            || (dateInsertStart >= dateBdStart && dateInsertEnd <= dateBdEnd)) {
            setBchoix(false)
            setVoitureOccupe(true)
            setDate({
              debut: dateBdStart.toLocaleDateString(),
              term: dateBdEnd.toLocaleDateString()
            })
          }
          else {
            setBchoix(false)
          }
        })
      }
    })

  }, [proposVoiture])

  // LOCATION

  const Location = (e) => {
    const nom = (document.getElementById("nom"))?.value
    const prenom = (document.getElementById("prenom"))?.value
    const email = (document.getElementById("email"))?.value
    const numero = (document.getElementById("numero"))?.value
    const province = (document.getElementById('destination'))?.value
    const trajet = document.getElementById('trajet')?.value

    const [start_jour, start_mois, start_annee] = (proposVoiture.start).split('/')
    const res_start = [start_mois, start_jour, start_annee].join('/')
    const [end_jour, end_mois, end_annee] = (proposVoiture.end).split('/')
    const res_end = [end_mois, end_jour, end_annee].join('/')

    let newDate1 = new Date(res_start)
    let newDate2 = new Date(res_end)

    let diff_time = Math.abs(newDate2 - newDate1)
    let jour = Math.ceil(diff_time / (1000 * 60 * 60 * 24))
    const raison = ""
    if (nom !== "" && prenom !== "" && email !== "" && numero !== "" && province !== "" && trajet !== "") {
      LocationVoiture(proposVoiture.marque, proposVoiture.idVoiture, nom, prenom, email, numero, dateSendBack(proposVoiture.start), dateSendBack(proposVoiture.end), province, trajet, jour, proposVoiture.prix, raison)
      window.location.href = "/#/admin/location"
    }
  }
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>

      {/* MODAL DATE DEJA EXISTANT */}
      <Modal className="modal" show={voitureOccupe} onHide={() => setVoitureOccupe(false)} backdrop='static' keyboard={false} centered>
        <Modal.Body>
          <div className="d-flex justify-content-center w-100 px-4">
            <p className="text-center">Cette voiture est déjà réserver le <b>{date.debut}</b> jusqu'au <b>{date.term}</b>. Veuillez choisir une autre date ou une autre voiture svp!</p>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center w-100">
            <button className="theme-btn w-25 my-1 bg-danger text-light" onClick={() => {
              setVoitureOccupe(false)
              setBchoix(true)
            }}>OK</button>
          </div>
        </Modal.Body>
      </Modal>
      {/* MODAL DATE DEJA EXISTANT */}

      {/* MODAL FILTRE DATE */}

      <Modal show={bChoix} onHide={() => setBchoix(false)} scrollable={true} backdrop='static' keyboard={false} centered className="border-0" size="md">
        <Modal.Header className='border-0' closeButton onClick={() => {
          setBchoix(false)
          window.location.href = "/#/admin/calendrier"
        }}>
          <div className="w-100 d-flex justify-content-center">
            <p className="modalTitre text-danger">Veuillez choisir le formulaire a remplir: </p>
          </div>
        </Modal.Header>
        <Modal.Body>

          <div className="col-12 col-md-12 col-lg-12 d-flex flex-column align-items-center">
            <div className="bChoix col-8 col-md-8 col-lg-8 my-2">
              <button onClick={() => {
                window.location.href = '/#/admin/reserver'
              }} className="w-100 d-flex justify-content-center py-3" id="bReservation">Formulaire de réservation de chambre</button>
            </div>
            <div className="bChoix col-8 col-md-8 col-lg-8 my-2">
              <button className="w-100 d-flex justify-content-center py-3 bg-danger text-light" id="bLocation">Formulaire de location de voiture</button>
            </div>
          </div>
          {/* LOCATION */}

          <div className="location form-group row" id="location">
            <div className="form-group col-12 col-md-12 col-lg-12 d-flex flex-wrap justify-content-around">
              
              <div className="col-12 col-md-12 col-lg-12 d-flex justify-content-evenly my-2">
                <label htmlFor="" className="col-3 col-form-label">Voiture : </label>
                <div className="form-field col-8 col-md-8 col-lg-8">
                  <select id="voiture" className="form-control" required>
                    <option value="" hidden>Choisir la marque de la voiture</option>
                    {listeVoiture.map((uneVoiture, index) => {
                      return <option value={`${uneVoiture._id}/${uneVoiture.marque}/${uneVoiture.prix}`} key={index} >{uneVoiture.marque}</option>
                    })};
                  </select>
                </div>
              </div>
              <div className="col-12 col-md-12 col-lg-12 d-flex justify-content-evenly my-2">
                <label htmlFor="" className="col-3 col-form-label">Check In : </label>
                <div className="form-field col-8 col-md-8 col-lg-8">
                  <input type="date" id="startLocation" className="form-control py-2" required/>
                </div>
              </div>
              <div className="col-12 col-md-12 col-lg-12 d-flex justify-content-evenly my-2">
                <label htmlFor="" className="col-3 col-form-label">Check Out : </label>
                <div className="form-field col-8 col-md-8 col-lg-8">
                  <input type="date" id="endLocation" className="form-control py-2" required/>
                </div>
              </div>
            </div>
            <div className="submit-area mt-4 col-12 col-md-12 col-lg-12 d-flex justify-content-end">
              <button className="btn btn-danger" id="bDispo" onClick={() => {
                const voiture = document.getElementById('voiture')?.value
                const voitureListe = voiture.split('/')
                const idVoiture = voitureListe[0]
                const marque = voitureListe[1]
                const prix = voitureListe[2]
                const start = document.getElementById('startLocation')?.value
                const end = document.getElementById('endLocation')?.value
                  setProposVoiture({
                    idVoiture: idVoiture,
                    marque: marque,
                    prix: prix,
                    start: new Date(start).toLocaleDateString(),
                    end: new Date(end).toLocaleDateString()
                  })
              }}>Voir la disponibilité</button>
            </div>
          </div>
          {/* LOCATION */}
        </Modal.Body>
      </Modal>

      {/* MODAL FILTRE DATE  */}
      <select name="choixListe" id="choixListe" onClick={() => liste()} className="text-light bg-danger selectChoix my-3 p-2">
        <option value="" hidden>Chambre / Location</option>
        <option value="chambre" className='text-light'>Formulaire de réservation de chambre</option>
        <option value="location" className='text-light'>Formulaire de location de voiture</option>
      </select>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mb='20px'>
        <form action="">
          <div className="form-group row">
            <div className="col-12 col-md-12 col-lg-12 d-flex flex-wrap justify-content-around">
              <div className="col-5 col-md-5 col-lg-5 my-3">
                <input id="nom" className="form-control py-2" type="text" name="nom" placeholder="Nom*" required />
              </div>
              <div className="col-5 col-md-5 col-lg-5 my-3">
                <input id="prenom" className="form-control py-2" type="text" name="prenom" placeholder="Prénom*" required />
              </div>
              <div className="col-5 col-md-5 col-lg-5 my-3">
                <input id="email" className="form-control py-2" type="email" name="email" placeholder="Email*" required />
              </div>
              <div className="col-5 col-md-5 col-lg-5 my-3">
                <input id="numero" className="form-control py-2" type="number" name="numero" placeholder="Numéro*" required />
              </div>
              <div className="d-flex col-5 col-md-5 col-lg-5 justify-content-between my-3">
                <label htmlFor="" className="col-6 d-flex justify-content-end col-form-label">Check in : </label>
                <div className="col-5 col-md-5 col-lg-5">
                  <input id="start" className="form-control" placeholder={proposVoiture?.start} type="text" name="start" disabled />
                </div>
              </div>
              <div className="d-flex col-5 col-md-5 col-lg-5 justify-content-between my-3">
                <label htmlFor="" className="col-form-label">Marque de voiture : </label>
                <div className="col-7 col-md-7 col-lg-7">
                  <input type="text" name="marque" id="marque" className="form-control" placeholder={proposVoiture?.marque} disabled />
                </div>
              </div>
              <div className="d-flex col-5 col-md-5 col-lg-5 justify-content-between my-3">
                <label htmlFor="" className="col-6 d-flex justify-content-end col-form-label">Check out : </label>
                <div className="col-5 col-md-5 col-lg-5">
                  <input type="text" id="end" className="form-control" placeholder={proposVoiture?.end} name="end" disabled />
                </div>
              </div>
              <div className="d-flex col-5 col-md-5 col-lg-5 justify-content-between my-3">
                <label htmlFor="" className="col-form-label">Déstination : </label>
                <div className="col-7 col-md-7 col-lg-7">
                  <select name="destination" id="destination" className="form-control" required>
                    <option value="" hidden>Choisir la déstination</option>
                    <option value="Tananarive">Tananarive</option>
                    <option value="Hors Tananarive">Hors Tananarive</option>
                  </select>
                </div>
              </div>
              <div className="d-flex col-5 col-md-5 col-lg-5 justify-content-between my-3">
              </div>
              <div className="d-flex col-5 col-md-5 col-lg-5 justify-content-between my-3">
                <label htmlFor="" className="col-form-label">Explication du trajet : </label>
                <div className="col-7 col-md-7 col-lg-7">
                  <textarea id="trajet" className="form-control" type="text-area" name="trajet" required />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-12 d-flex justify-content-end my-3">
              <div className="submit-area d-flex justify-content-end col-12 mx-5 ">
                <button type="submit" className="btn btn-danger w-25" onClick={(e) => {
                  Location(e);
                  e.preventDefault()
                }}>Confirmer la réservation</button>
              </div>
            </div>
          </div>
        </form>
      </SimpleGrid>
    </Box>
  );
}