import axios from "./axios";

async function getAllEditableTexts() {
  const response = await axios.get(`/editable-texts`);
  return response.data;
}

async function updateEditableText(key, value) {
  const response = await axios.put(`/editable-texts`, {
    key,
    value,
  });

  return response.data;
}

export { getAllEditableTexts, updateEditableText };
