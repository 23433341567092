
import {
    Box,
    SimpleGrid,
  } from "@chakra-ui/react";
  import React from "react";
  import TableLocationBloquee from "./components/tableLocationBloquee";
  export default function UserReports() {
    return (
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
       
        <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mb='20px'>
          <TableLocationBloquee/>
        </SimpleGrid>
      </Box>
    );
  }
  