import React, { useState,useRef } from 'react';
import axios from 'axios';
import { FaFileExport,FaFileImport  } from "react-icons/fa6";
import { CircularProgress } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { FaTrashAlt } from 'react-icons/fa';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import { Bounce, toast } from "react-toastify";
import moment from 'moment';
import 'moment/locale/fr'; 


const ExportButton = () => {
    const [importLoading,setImportLoading] = useState(false);
    const [exportLoading,setExportLoading] = useState(false);
    const [deleteLoading,setDeleteLoading] = useState(false);
    const fileInputRef = useRef(null);
    const history = useHistory();

    const handleDownload = () => {
        setExportLoading(true);
        const today = moment().locale('fr').format('DD/MM/YYYY_HH-mm');
        const filename = `breakfast_${today}.csv`;       
        axios({
            url: process.env.REACT_APP_BACK_URL + '/breakfasts/export-csv',
            method: 'GET',
            responseType: 'blob', 
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            toast.dismiss();
            toast.success('Exportation du fichier réussie!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
            
        }).catch(()=>{
            toast.dismiss();
            toast.error('Une erreur est survenue...', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
            
        }).finally(()=>setExportLoading(false));
    }

    const handleImport = async () => {
        setImportLoading(true);
        
        try {
            const formData = new FormData();
            formData.append('file', fileInputRef.current.files[0]);

            const response = await axios.post(
                `${process.env.REACT_APP_BACK_URL}/breakfasts/import-csv`, // Assurez-vous de remplacer cette URL par celle de votre endpoint backend
                formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                }
                );

                history.go(0); 
                return response.data;
        } catch (error) {

            toast.dismiss();
            toast.error('Une erreur est survenue...', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
        }finally{   
            setImportLoading(false);
        }
    }

    const exportStyle = {
        cursor: "pointer",
        width: "fit-content",
        padding: "10px 10px", 
        display: "flex", 
        border: "1px solid grey",
        alignItems: "center",
        gap: "15px",
        borderRadius: "3px"
    }

    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' , borderBottom: "1px solid grey", paddingBottom: "15px" }}>
        <ConfirmDeleteModal show={deleteLoading} onHide={()=>setDeleteLoading(false)}/>
        <div onClick={handleDownload} style={exportStyle}>
            {!exportLoading ? (
                    <FaFileExport color="#FF5722" />
                    ) : <CircularProgress size={20} color="inherit" /> }
                    Exporter les données
        </div>
                
        <form action="/upload" method="post" encType="multipart/form-data">
                <input ref={fileInputRef} type="file" style={{ display: 'none' }} name="file" onChange={handleImport}  onInput={()=>setImportLoading(true)}/>
                <div onClick={() => fileInputRef.current.click()}   style={exportStyle}>
                    {!importLoading ? (
                    <FaFileImport color="#4CAF50" />
                    ) : <CircularProgress size={20} color="inherit" /> }
                    Importer des données CSV
                        
                </div>

                <button type="submit" style={{ display: 'none' }}>Submit</button>
        </form>

        <div onClick={() => setDeleteLoading(true)} style={exportStyle}>
                {!deleteLoading ? (
                <FaTrashAlt color="red" />
                ) : <CircularProgress size={20} color="inherit" /> }
                    Supprimer toutes les données
        </div>

      </div>
    );
}

export default ExportButton;
