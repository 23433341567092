import React, { useEffect } from "react";

function SignIn() {
  useEffect(() => {
    localStorage.removeItem("token");
    window.location.href = "/#/admin";
  }, []);

  return <div></div>;
}

export default SignIn;
