import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function DeleteModal(allProps) {
  const { onDelete, loading, ...props } = allProps;

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Suppression
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="h5 lead">
          Voulez-vous vraiment supprimer cette reservation ?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={loading}
          onClick={props.onHide}
          variant={"outline-secondary"}
        >
          Annuler
        </Button>
        <Button
          onClick={() => {
            onDelete();
          }}
          variant={"danger"}
          disabled={loading}
          style={{
            opacity: loading ? 0.7 : 1,
          }}
        >
          {loading ? "En cours..." : "Supprimer"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteModal;
