import {
  Link
} from "react-router-dom";
import {
  Box,
  SimpleGrid,
} from "@chakra-ui/react";
import React from "react";
import { insererBlog } from '../../../api/Blog';
import TableChambre from "./components/TableChambre";
export default function UserReports() {
   
  const SupprimerAvis = (e) => {
    const image=(document.getElementById("image").files[0]);
  //   setFile(URL.createObjectURL(image));
    const titre = (document.getElementById("titre")).value
    const texte = (document.getElementById("texte")).value
    console.log(image['name']);
    insererBlog(titre, texte, image['name']);
    window.location.reload(false);
  }
  return (

    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>

      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mb='20px'>
          <TableChambre/>
      </SimpleGrid>
    </Box>
  );
}