import { Box, SimpleGrid } from "@chakra-ui/react";
import React from "react";
import TableChambre from "./components/TableChambre";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import CarLocations from "./CarLocations"; 

export default function UserReports() {
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
        <Tabs isFitted variant="enclosed" mt={5}>
          <TabList>
            <Tab>Reservation Chambre</Tab>
            <Tab>Reservation Voiture</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <TableChambre />
            </TabPanel>
            <TabPanel>
              <CarLocations />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </SimpleGrid>
    </Box>
  );
}
