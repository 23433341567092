import {
    Link
  } from "react-router-dom";
  import {
    Box,
    SimpleGrid,
  } from "@chakra-ui/react";
  import { getHotelType, getAllCategorie} from "../../../api/Chambre";
  import React, { useEffect, useState } from "react";
  export default function UserReports() {
    const [hotelType, setHotelType] = useState([]);
    const [categorie, setCategorie] = useState([]);
    
    useEffect(() => {
      if (hotelType.length === 0 ) {
        getHotelType().then((hotelType) => {
          setHotelType(hotelType);
        });
        getAllCategorie().then((listeCategorie) => {
          setCategorie(listeCategorie);
        });
      }
    }, [hotelType.length]);
    const Enregistrer = (e) => {
      const form = document.getElementById('formChambre')
      form.submit()
    }
    
    return (
  
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
  
        <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mb='20px'>
        <form id="formChambre" action="http://localhost:8000/hotel/creerChambre" method="post" encType='multipart/form-data'>
        {/* <form id="formChambre" action="https://root.valihahotel.com/hotel/creerChambre" method="post" encType='multipart/form-data'     > */}
            <div className="row">
              <div className="col col-lg-6 col-12">
                <div className="form-field">
                  <label>
                    Intitule :
                    <input className="form-control" id="intitule" type="text" name="intitule"  placeholder="Intitule" />
                  </label>
                </div>
              </div>
              <div className="col col-lg-6 col-12">
                <div className="form-field">
                  <label>
                    Prix :
                    <input className="form-control"  id="prix" type="number" name="prix"  placeholder="Prix" />
                  </label>
                </div>
              </div>
              <div className="col col-lg-6 col-12 mt-4">
                <div className="form-field">
                  <label>
                    Hotel Type :
                    <select name="idHotelType" id="idHotelType" className="form-control">
                      {hotelType.map((uneHotelType, index) => {
                          return <option value={uneHotelType._id} key={index} >{uneHotelType.type}</option>
                      })};
                    </select>
                  </label>
                </div>
              </div>
              <div className="col col-lg-6 col-12 mt-4">
                <div className="form-field">
                  <label>
                    Catégorie :
                    <select name="idCategorie" id="idCategorie" className="form-control">
                      {categorie.map((uneCategorie, index) => {
                          return <option value={uneCategorie._id} key={index} >{uneCategorie.intitule}</option>
                      })};
                    </select>
                  </label>
                </div>
              </div>
            </div>
            <div className="col col-lg-6 col-12 mt-4">
                <div className="form-field">
                  <label>
                    Image :
                    <input className="form-control"  id="chambreImage" type="file" name="chambreImage"  />
                  </label>
                </div>
              </div>
            <div className="submit-area mt-4">
              <Link onClick={(e) => {
                // ClickHandler()
                Enregistrer(e);
              }} to="#" className="btn btn-danger">Valider</Link>
            </div>
          </form >
        </SimpleGrid>
      </Box>
    );
  }