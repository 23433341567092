import {
  Link, useParams
} from "react-router-dom";
import {
  Box,
  SimpleGrid,
} from "@chakra-ui/react";
import { getOneChambre, getHotelType, getAllCategorie,modifierChambre } from "../../../api/Chambre";
import React, { useEffect, useState } from "react";
export default function UserReports() {
  const [chambre, setListChambre] = useState([]);
  const [hotelType, setHotelType] = useState([]);
  const [categorie, setCategorie] = useState([]);
  const { id } = useParams();
  const [forms, setForms] = useState({
    nom: '',
    intitule: '',
    email: '',
    hotelType: '',
    categorie: '',
  });
  useEffect(() => {
    if (chambre.length !== 1) {
      getOneChambre(id).then((listeChambre) => {
        setListChambre(listeChambre);
        setForms({ ...forms, intitule: listeChambre[0].intitule, prix: listeChambre[0].prix, hotelType: listeChambre[0].idHotelType, categorie: listeChambre[0].idCategorie, image:listeChambre[0].img});
      });
      getHotelType().then((hotelType) => {
        setHotelType(hotelType);
      });
      getAllCategorie().then((listeCategorie) => {
        setCategorie(listeCategorie);
      });
    }
  }, [forms, id, chambre.length]);
  const changeHandler = e => {
    setForms({ ...forms, [e.target.name]: e.target.value })
  };
  const Enregistrer = (e) => {
    const intitule = (document.getElementById("intitule")).value
    const prix = (document.getElementById("prix")).value
    const hotelType = (document.getElementById("hotelType")).value
    const categorie = (document.getElementById("categorie")).value
    // const image = (document.getElementById("image")).value

    // window.alert('intitule : ' +intitule+ ' prix: '+prix+' hotelType: '+hotelType+ ' categorie: '+categorie+ ' id: '+id);
    modifierChambre(id, intitule, prix, categorie, hotelType)
  }

  return (

    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>

      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mb='20px'>
        <form className="contact-validation-active" >
          <div className="row">
            <div className="col col-lg-4 col-12">
              <div className="form-field">
                <label>
                  Intitule :
                  <input id="intitule" className="form-control" value={forms.intitule} type="text" name="intitule"   onBlur={(e) => changeHandler(e)} onChange={(e) => changeHandler(e)} placeholder="Intitule" />
                </label>
              </div>
            </div>
            <div className="col col-lg-4 col-12">
              <div className="form-field">
                <label>
                  Prix :
                  <input  id="prix" className="form-control" value={forms.prix} type="number" name="prix" onBlur={(e) => changeHandler(e)} onChange={(e) => changeHandler(e)} placeholder="Prix" />
                    
                </label>
              </div>
            </div>
            <div className="col col-lg-4 col-12">
              <div className="form-field">
                <label>
                  Hotel Type :
                  <select name="hotelType" id="hotelType" className="form-control">
                    {hotelType.map((uneHotelType, index) => {
                      if (uneHotelType._id === forms.hotelType._id) {
                        return <option selected value={uneHotelType._id}>{uneHotelType.type}</option>
                      } else {
                        return <option value={uneHotelType._id}>{uneHotelType.type}</option>
                      }
                    })};
                  </select>
                </label>
              </div>
            </div>
            <div className="col col-lg-4 col-12">
              <div className="form-field">
                <label>
                  Catégorie :
                  <select name="categorie" id="categorie" className="form-control">
                    {categorie.map((uneCategorie, index) => {
                      if (uneCategorie._id === forms.categorie._id) {
                        return <option selected value={uneCategorie._id}>{uneCategorie.intitule}</option>
                      } else {
                        return <option value={uneCategorie._id}>{uneCategorie.intitule}</option>
                      }
                    })};
                  </select>
                </label>
              </div>
            </div>
          </div>

          <div className="submit-area">
            <Link onClick={(e) => {
              Enregistrer(e)
            }} to="#" className="btn btn-danger mt-2">Valider</Link>
          </div>
        </form >
      </SimpleGrid>
    </Box>
  );
}