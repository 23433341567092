import {Link, useParams} from "react-router-dom";
import {Box,SimpleGrid, Grid} from "@chakra-ui/react";

import { getAllTypePaie } from "../../../api/Paiement";
import {payerLocation} from "../../../api/paiementLocation"
import React, { useEffect, useState } from "react";
import { getLocationId } from "api/location";
import GeneralInformation from "../location/components/General";

export default function UserReports() {
  const [location, setLocation] = useState([])
  const [typePaie, setTypePaie] = useState([]);
  const { id } = useParams();
  const [montant, setMontant] = useState(0);
  
  useEffect(async () => {
   await getLocationId(id).then((uneLocation) => {
     setLocation(uneLocation)
     setMontant(uneLocation?.jour * uneLocation?.prix)
    });

    getAllTypePaie().then((typePaie) => {
      setTypePaie(typePaie);
    });
  }, []);
  const Enregistrer = () => {
    const typePaie = (document.getElementById("typePaie")).value;
    const remise = (document.getElementById("remise")).value;
    payerLocation(id, typePaie, montant, remise)
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}
      <Grid>
        <GeneralInformation
          location={location}
          gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
          minH='365px'
          pe='20px'
        />
      </Grid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mb='20px'>
        <form className="contact-validation-active" >
          <div className="row">
            <div className="col col-lg-4 col-12">
              <div className="form-field">
                <label>
                  Type de paiement :
                  <select name="typePaie" id="typePaie" className="form-control">
                    {typePaie.map((uneTypePaie) => {
                      return <option value={uneTypePaie._id} key={uneTypePaie._id}>{uneTypePaie.type}</option>
                    })};
                  </select>
                </label>
              </div>
            </div>
            <div className="col col-lg-4 col-12">
              <div className="form-field">
                <label>
                  Remise :
                  <input id="remise" className="form-control" type="number"  name="remise" placeholder="en %" min="0" />
                </label>
              </div>
            </div>
          </div>
          <div className="submit-area">
                <Link onClick = {(e) => {
                  Enregistrer(e)
                  ;}
                } to = "/admin/location" className = "btn btn-danger mt-2">Valider</Link>
        </div>
      </form >
    </SimpleGrid>
      </Box >
    );
}