import React, { useEffect, useState } from "react";
import { useParams , useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { modifierChambre } from "../../../../api/Chambre";
import { toast , Bounce } from 'react-toastify';

const ModifyModal = ({show,handleHide, roomContent, hotelType, categorie}) => {

  const history = useHistory();
  const { id } = useParams();
  const [forms, setForms] = useState();

  useEffect(() => {
        if (roomContent !== '') {
          setForms({ ...forms, intitule: roomContent?.intitule, prix: roomContent?.prix, hotelType: roomContent?.idHotelType, categorie: roomContent?.idCategorie, image: roomContent?.img });
        }
  }, [roomContent]);
  
  const changeHandler = e => {
    setForms({ ...forms, [e.target.name]: e.target.value })
  };

  const saveModificationRoom = async (e) => {
    e.preventDefault();

    const form = document.getElementById("formChambreModify");
    const formData = new FormData(form);

    const intitule = formData.get("intitule");
    const prix = formData.get("prix");
    const hotelType = formData.get("hotelType");
    const categorie = formData.get("categorie");

  try {
      await modifierChambre(roomContent._id, intitule, prix, categorie, hotelType);
      // history.go(0);
      handleHide();

      toast.success("Changement effectué", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    } catch (error) {
      toast.error("Une erreur s'est produite", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  if (!forms) {
    return 
  }


  return (
    <Modal show={show} onHide={handleHide} scrollable={true} backdrop="static" keyboard={false} centered className="border-0" size="md">
      <Modal.Header closeButton>
        <div className="w-100 d-flex justify-content-center">
          <p className="titreModal ">Modification de la chambre</p>
        </div>
      </Modal.Header>
      <Modal.Body>
        <form className="contact-validation-active" id="formChambreModify">
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12 d-flex justify-content-evenly my-2">
              <label htmlFor="" className="col-3 col-form-label">
                Intitule :
              </label>
              <div className="form-field col-8 col-md-8 col-lg-8">
                <input
                  id="intitule"
                  className="form-control"
                  value={forms.intitule || ""}
                  type="text"
                  name="intitule"
                  onBlur={(e) => changeHandler(e)}
                  onChange={(e) => changeHandler(e)}
                  placeholder="Intitule"
                />
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-12 d-flex justify-content-evenly my-2">
              <label htmlFor="" className="col-3 col-form-label">
                Prix :
              </label>
              <div className="form-field col-8 col-md-8 col-lg-8">
                <input id="prix" className="form-control" value={forms.prix || 0} type="number" name="prix" onBlur={(e) => changeHandler(e)} onChange={(e) => changeHandler(e)} placeholder="Prix" />
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-12 d-flex justify-content-evenly my-2">
              <label htmlFor="" className="col-3 col-form-label">
                Hotel Type :
              </label>
              <div className="form-field col-8 col-md-8 col-lg-8">
                <select name="hotelType" id="hotelType" className="form-control" value={forms.hotelType._id} onChange={changeHandler}>
                  {hotelType.map((uneHotelType) => {
                    return (
                      <option key={uneHotelType._id} value={uneHotelType._id}>
                        {uneHotelType.type}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-12 d-flex justify-content-evenly my-2">
              <label htmlFor="" className="col-3 col-form-label">
                Catégorie :
              </label>
              <div className="form-field col-8 col-md-8 col-lg-8">
                <select name="categorie" id="categorie" className="form-control" value={forms.categorie._id} onChange={changeHandler}>
                  {categorie.map((uneCategorie) => {
                    return (
                      <option key={uneCategorie._id} value={uneCategorie._id}>
                        {uneCategorie?.intitule}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>

          <div className="submit-area" style={{ float: "right" }}>
            <button
              className="btn mt-2"
              style={{ background: "#2ecc71", color: "white", borderRadius: "3px" }}
              onClick={(e) => {
                saveModificationRoom(e);
              }}
            >
              Modifier
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ModifyModal;
