import axiosInstance from "./axios";

export const getVoiture = async () => {
  return await axiosInstance
    .get("/voiture/getVoiture")
    .then((listeVoiture) => {
      return listeVoiture.data;
    })
    .catch((err) => {
      console.log(err);
    });
};
export const deleteVoiture = async (id) => {
  return await axiosInstance
    .delete("voiture/deleteVoiture/" + id, {})
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const ajoutVoiture = async (
  marque,
  entrainement,
  kilometrage,
  portes,
  places,
  annee,
  prix,
  img
) => {
  try {
    return await axiosInstance
      .post("/voiture/creerVoiture", {
        marque: marque,
        entrainement: entrainement,
        kilometrage: kilometrage,
        portes: portes,
        places: places,
        annee: annee,
        prix: prix,
        img: img,
      })
      .then((resultat) => {
        alert(resultat.data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (err) {
    console.log(err);
  }
};
