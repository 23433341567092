import React from "react";
import { Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableContainer, Button, Flex } from "@chakra-ui/react";
import { IoMdEye } from "react-icons/io";
import { IoIosEyeOff } from "react-icons/io";
import { FaPen } from "react-icons/fa";
import { FaTrash } from "react-icons/fa";
import { Droppable, Draggable } from "react-beautiful-dnd";
import Section from "./Section";

const Menu = (props) => {
  const { droppableType, hidePrice = false, provided = {}, snapshot = {}, breakfast, disableVisibilityControl, disableDrag = false, hideSectionPrice = false, disableDelete = false } = props;

  const { onToggleVisibility, onEdit, onDelete, onAdd, sectionActions } = props;

  return (
    <TableContainer
      {...(disableDrag ? {} : provided.draggableProps)}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
      style={{
        borderWidth: snapshot.isDragging ? 1 : 0,
        borderColor: snapshot.isDragging ? "#dddddd" : "white",
        padding: "20px",
        borderRadius: "10px",
        background: snapshot.isDragging ? "#eeeeee" : "white",
        marginTop: "10px",
        color: breakfast.hidden ? "rgba(50, 50, 50, 0.3)" : "",
        ...provided.draggableProps?.style,
      }}
      mb={5}
      pb={5}
    >
      <Flex align="center" mt={5} mb={4}>
        <h1 className="font-bold me-3">
          {breakfast.title} {!hidePrice && <span className="ml-3 font-normal">| AR {breakfast.price}</span>}
        </h1>
        <Flex>
          {!disableVisibilityControl && (
            <Button
              variant="ghost"
              mr={2}
              onClick={() => {
                onToggleVisibility(!Boolean(breakfast.hidden));
              }}
              title="Cacher / Afficher sur le site"
            >
              {breakfast.hidden ? <IoIosEyeOff color="#dddddd" /> : <IoMdEye />}
            </Button>
          )}
          <Button colorScheme="blue" variant="ghost" mr={2} onClick={onEdit} title="Modifier">
            <FaPen />
          </Button>
          {!disableDelete && (
            <Button colorScheme="red" variant="ghost" title="Supprimer" onClick={onDelete}>
              <FaTrash />
            </Button>
          )}
        </Flex>
      </Flex>
      {breakfast.description && (
        <h2
          className="mr-3 mb-3 -mt-2"
          style={{
            width: "100%",
            maxWidth: "600px",
            whiteSpace: "pre-wrap",
          }}
        >
          {breakfast.description}
        </h2>
      )}
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Section</Th>
            <Th width={"100%"} maxWidth={"100%"}>
              Valeurs
            </Th>
            <Th>Selection</Th>
            {!hideSectionPrice && <Th>Prix</Th>}
            <Th colSpan={4}></Th>
          </Tr>
        </Thead>
        <Droppable droppableId={breakfast._id} type={droppableType}>
          {(provided) => (
            <Tbody {...provided.droppableProps} ref={provided.innerRef}>
              {breakfast.options.map((option, i) => (
                <Draggable draggableId={option._id} key={option._id} index={i}>
                  {(provided, snapshot2) => (
                    <Section
                      option={option}
                      index={i}
                      hidePrice={hideSectionPrice}
                      provided={provided}
                      snapshot={snapshot2}
                      parentSnapshot={snapshot}
                      onToggleVisibility={(value) => {
                        sectionActions.onToggleVisibility(option, value);
                      }}
                      onDuplicate={() => sectionActions.onDuplicate(option, i)}
                      onEdit={() => sectionActions.onEdit(option)}
                      onDelete={() => sectionActions.onDelete(option)}
                    />
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Tbody>
          )}
        </Droppable>
        <Tfoot
          style={{
            background: snapshot.isDragging ? "#eeeeee" : "white",
          }}
        >
          <Tr>
            <Td colSpan={8}>
              <Button onClick={onAdd} colorScheme="gray" width={"100%"} variant="outline">
                + Ajouter une section
              </Button>
            </Td>
          </Tr>
        </Tfoot>
      </Table>
    </TableContainer>
  );
};

export default Menu;
