import {Flex,Table,Tbody,Td,Text,Th,Thead,Tr,useColorModeValue} from "@chakra-ui/react";
  import React, { useEffect, useState } from "react";
  import {Link} from "react-router-dom";
  // Custom components
  import Card from "components/card/Card";
import { getLocationBloquee } from "api/location";
import {Modal} from "react-bootstrap"
import { getVoiture } from "api/Voiture";
import { setEtatFini, bloquerVoiture } from "api/location";

  // Assets
  export default function ColumnsTable(props) {
    const [voiture, setListeVoiture] = useState([]);
    const [voitureBloquee, setListeVoitureBloquee] = useState([]);

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  
      useEffect(() => {
          getLocationBloquee().then((listeVoiture) => {
            setListeVoitureBloquee(listeVoiture)
          });
      }, [voitureBloquee]);

      useEffect(()=>{
        getVoiture().then((listeVoiture)=>{
          setListeVoiture(listeVoiture)
        })
      },[])
    
      function debloquer(id) {
        if (window.confirm("Voulez-vous vraiment débloquer cette voiture?")) {
          setEtatFini(id);
          const newVoitures = voiture.filter((el) => el._id !== id);
          setListeVoiture(newVoitures);
        }
      }
      const [selectedValue, setSelectedValue] = useState('')
      useEffect(() => {
        liste()
      }, [selectedValue])
      const liste = () => {
        const choixListe = document.getElementById("choixListe")?.value
        setSelectedValue(choixListe)
        if (selectedValue === "chambre") {
          window.location.href = "/#/admin/bloque"
        }
        else if (selectedValue === "location") {
          window.location.href = "/#/admin/voitureBloquee"
        }
      }
      const [voitureBloquer, setVoitureBloquer] = useState(false)
      var curr = new Date().toISOString().substring(0,10);
      
      const Reservation = (e)=>{
        const start = (document.getElementById("start"))?.value
        const end = (document.getElementById("end"))?.value
        const raison = (document.getElementById("raison"))?.value
        const voiture = (document.getElementById("voiture"))?.value
        const newVoiture = voiture.split('/')
        const idVoiture = newVoiture[0]
        const marque = newVoiture[1]
        bloquerVoiture(idVoiture, marque, start, end, raison)
      }

    return (
      <Card direction='column'  w='100%'  px='0px' overflowX={{ sm: "scroll", lg: "hidden" }}>
        <div className="col-12 d-flex align-items-baseline">
        <div className="submit-area mb-2 col-6">
          <select name="choixListe" id="choixListe" onClick={() => liste()} className="text-light bg-danger selectChoix my-3 p-2">
            <option value="" hidden>Chambre / Voiture</option>
            <option value="chambre" className='text-light'>Liste des chambres bloquées</option>
            <option value="location" className='text-light'>Liste des voitures bloquées</option>
          </select>
        </div>
      </div>
        <Flex px='25px' justify='space-between' mb='10px' align='center'>
          <Text color={textColor} fontSize='22px' fontWeight='700' lineHeight='100%'>
            Liste des voitures bloquées
          </Text>
        </Flex>
        <div className="submit-area mb-2 col-12">
          <button onClick={() => {
            setVoitureBloquer(true)
          }} className="btn text-light rounded-0 bg-danger border-0 col-3">Bloquer une voiture</button>
        </div>
        <Modal show={voitureBloquer} onHide={() => setVoitureBloquer(false)} scrollable={true} backdrop='static' keyboard={false} centered className="border-0" size="md">
          <Modal.Header closeButton onClick={() => setVoitureBloquer(false)}>
            <div className="w-100 d-flex justify-content-center">
              <p className="titreModal text-danger">Formulaire de blocage</p>
            </div>
          </Modal.Header>
          <Modal.Body>
            <form className="contact-validation-active">
              <div className="form-group row">
                <div className="form-group col-12 col-md-12 col-lg-12 d-flex flex-wrap justify-content-around">
                  <div className="col-12 col-md-12 col-lg-12 d-flex justify-content-evenly my-2">
                    <label htmlFor="" className="col-5 col-form-label">Marque de la voiture : </label>
                    <div className="form-field col-6 col-md-6 col-lg-6">
                      <select name="voiture" id="voiture" className="form-control">
                        <option value="" hidden>Choisir la voiture</option>
                        {voiture.map((uneVoiture, index) => {
                          return <option value={`${uneVoiture?._id}/${uneVoiture?.marque}`} key={index}>{uneVoiture?.marque}</option>
                        })};
                      </select>
                    </div>
                  </div>
                  <div className="col-12 col-md-12 col-lg-12 d-flex justify-content-evenly my-2">
                    <label htmlFor="" className="col-5 col-form-label">Bloquée le: </label>
                    <div className="form-field col-6 col-md-6 col-lg-6">
                      <input id="start" className="form-control" min={curr} defaultValue={curr} type="date" name="start" />
                    </div>
                  </div>
                  <div className="col-12 col-md-12 col-lg-12 d-flex justify-content-evenly my-2">
                    <label htmlFor="" className="col-5 col-form-label">Jusqu'au: </label>
                    <div className="form-field col-6 col-md-6 col-lg-6">
                      <input id="end" className="form-control" min={curr} defaultValue={curr} type="date" name="end" />
                    </div>
                  </div>
                  <div className="col-12 col-md-12 col-lg-12 d-flex justify-content-evenly my-2">
                    <label htmlFor="" className="col-5 col-form-label">Raison du blocage : </label>
                    <div className="form-field col-6 col-md-6 col-lg-6">
                      <select name="raison" id="raison" className="form-control">
                        <option value="" hidden>Choisir la raison</option>
                        <option value="Réparation de la voiture">Réparation de la voiture</option>
                        <option value="Changement de fonction">Changement de fonction</option>
                        <option value="Autre raison">Autre raison</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="submit-area mt-4 col-12 col-md-12 col-lg-12 d-flex justify-content-end">
                <Link className="btn btn-danger" onClick={(e) => {
                  Reservation(e)
                  setVoitureBloquer(false)
                }} to="/admin/voitureBloquee">Bloquer cette voiture</Link>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        <Table  variant='simple' color='gray.500' mb='24px'>
          <Thead>
              <Tr>
                <Th pe='10px' borderColor={borderColor}>Marque de la voiture
                    <Flex justify='space-between'  align='center' fontSize={{ sm: "10px", lg: "12px" }} color='gray.400'>
                    </Flex>
                </Th>
                <Th pe='10px' borderColor={borderColor}>Bloquée le 
                    <Flex justify='space-between' align='center'  fontSize={{ sm: "10px", lg: "12px" }} color='gray.400'>
                    </Flex>
                </Th>
                <Th pe='10px'  borderColor={borderColor}>Jusqu'au
                    <Flex justify='space-between' align='center' fontSize={{ sm: "10px", lg: "12px" }} color='gray.400'>
                    </Flex>
                </Th>
                <Th pe='10px' borderColor={borderColor}>Raison du blocage
                    <Flex justify='space-between' align='center' fontSize={{ sm: "10px", lg: "12px" }}  color='gray.400'>
                    </Flex>
                </Th>
              </Tr>
          </Thead>
          <Tbody>
            {voitureBloquee.map((uneVoiture) => {
              return (
                <Tr key={uneVoiture?._id}>
                {/* <Tr> */}
                      <Td fontSize={{ sm: "14px" }} maxH='30px !important' py='8px' minW={{ sm: "150px", md: "200px", lg: "auto" }} borderColor='transparent'>
                          <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {uneVoiture?.marque}
                          </Text>
                      </Td>
                      <Td fontSize={{ sm: "14px" }} maxH='30px !important' py='8px' minW={{ sm: "150px", md: "200px", lg: "auto" }} borderColor='transparent'>
                          <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {uneVoiture?.start.split('T')[0]}
                          </Text>
                      </Td>
                      <Td fontSize={{ sm: "14px" }} maxH='30px !important' py='8px' minW={{ sm: "150px", md: "200px", lg: "auto" }} borderColor='transparent'>
                          <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {uneVoiture?.end.split('T')[0]}
                          </Text>
                      </Td>
                      <Td fontSize={{ sm: "14px" }} maxH='30px !important' py='8px' minW={{ sm: "150px", md: "200px", lg: "auto" }} borderColor='transparent'>
                          <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {uneVoiture?.raison}
                          </Text>
                      </Td>
                      <Td fontSize={{ sm: "10px" }} maxH='20px !important' py='4px' minW={{ sm: "100px", md: "100px", lg: "auto" }} borderColor='transparent'>
                          <Link onClick={(e) => {
                            const id = uneVoiture?._id;
                            debloquer(id) ;
                            }} to="/admin/voitureBloquee">
                            <Text color={textColor} fontSize='sm' fontWeight='700' className="rounded bg-danger w-75 pl-3 py-2 text-light">Débloquer</Text>
                          </Link>
                      </Td>
                </Tr>
                ); 
             })}
          </Tbody>
        </Table>
      </Card>
    );
  }
  