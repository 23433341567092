import axios from "./axios";

export async function getAllBreakfasts(params) {
  const hiddenBreakfast = params?.hiddenBreakfast ?? true  
  const hiddenOption = params?.hiddenOption ?? true 
  
  const response = await axios.get("/breakfasts", {
    params: {
      hiddenBreakfast, 
      hiddenOption
    }
  });
  return response.data;
}

export async function createMenu({ title, description, price }) {
  const response = await axios.post("/breakfasts", {
    title,
    description,
    price,
  });
  return response.data;
}

export async function updateMenu({ breakfastId, title, description, price, hidden }) {
  const response = await axios.put(`/breakfasts/${breakfastId}`, {
    title,
    description,
    price,
    hidden
  });
  return response.data;
}

export async function updateMenuOrder({ updatedMenuItems }) {
  const response = await axios.put(`/breakfasts/order`, {
    updatedMenuItems,
  });
  return response.data;
}

export async function deleteMenu({ breakfastId }) {
  const response = await axios.delete(`/breakfasts/${breakfastId}`);
  return response.data;
}

export async function createSection({ breakfastId, title, values, price, multiple, hidden }) {
  const response = await axios.post(`/breakfasts/${breakfastId}/options`, {
    title,
    values,
    price,
    multiple,
    hidden
  });
  return response.data;
}

export async function updateSection({ breakfastId, optionId, title, values, price, multiple, hidden }) {
  const response = await axios.put(`/breakfasts/${breakfastId}/options/${optionId}`, {
    title,
    values,
    price,
    multiple,
    hidden
  });
  return response.data;
}

export async function duplicateSection({ breakfastId, placeOn, optionId }) {
  const response = await axios.post(`/breakfasts/${breakfastId}/options/${optionId}/duplicate`, {
    placeOn,
  });
  return response.data;
}

export async function deleteSection({ breakfastId, optionId }) {
  const response = await axios.delete(`/breakfasts/${breakfastId}/options/${optionId}`);
  return response.data;
}

export async function updateOptionsOrder({ breakfastId, updatedOptionsOrder }) {
  const response = await axios.put(`/breakfasts/${breakfastId}/options/order`, {
    updatedOptionsOrder,
  });

  return response.data;
}
