// Chakra imports
import { Box,SimpleGrid, Text, useColorModeValue } from "@chakra-ui/react";
import { getReservation } from "api/Reservation";
// Custom components
import Card from "components/card/Card.js";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
export default function GeneralInformation(props) {
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const {id} = useParams()
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const bg = useColorModeValue("white", "navy.700");
  const [listReservation, setListReservation] = useState([])
  const [montant, setMontant] = useState(0)
  useEffect(()=>{
    getReservation(id).then((uneReservation)=>{
      console.log(uneReservation)
      setListReservation(uneReservation)
      setMontant(uneReservation.reservation?.nuit * uneReservation.chambre?.prix)
    })
  },[])
  const formateDate = (date)=>{
    return new Date(date).toLocaleDateString()
  }
  return (
    <Card mb={{ base: "0px", "2xl": "60px" }} >
      <Text
        color={textColorPrimary}
        fontWeight='bold'
        fontSize='2xl'
        mt='10px'
        mb='4px'>
        Information du client
      </Text>
          <SimpleGrid columns='3' gap='40px' me='20px'>
            <Card bg={bg} boxShadow ={cardShadow}>
              <Box>
                <Text fontWeight='500' color={textColorSecondary} fontSize='sm'>
                Nom et prénom
                </Text>
                <Text color={textColorPrimary} fontWeight='500' fontSize='md'>
                {listReservation.reservation?.client?.prenom} <span className="uppercase">{listReservation.reservation?.client?.nom}</span>
                </Text>
              </Box>
            </Card>

            <Card bg={bg} boxShadow ={cardShadow}>
              <Box>
                <Text fontWeight='500' color={textColorSecondary} fontSize='sm'>
                Mail
                </Text>
                <Text color={textColorPrimary} fontWeight='500' fontSize='md'>
                {listReservation.reservation?.client?.mail}
                </Text>
              </Box>
            </Card>
            <Card bg={bg} boxShadow ={cardShadow}>
              <Box>
                <Text fontWeight='500' color={textColorSecondary} fontSize='sm'>
                Contact
                </Text>
                <Text color={textColorPrimary} fontWeight='500' fontSize='md'>
                {listReservation.reservation?.client?.numero}
                </Text>
              </Box>
            </Card>
            <Card bg={bg} boxShadow ={cardShadow}>
              <Box>
                <Text fontWeight='500' color={textColorSecondary} fontSize='sm'>
                Check In
                </Text>
                <Text color={textColorPrimary} fontWeight='500' fontSize='md'>
                {formateDate(listReservation.reservation?.start)}
                </Text>
              </Box>
            </Card>
            <Card bg={bg} boxShadow ={cardShadow}>
              <Box>
                <Text fontWeight='500' color={textColorSecondary} fontSize='sm'>
                Check Out
                </Text>
                <Text color={textColorPrimary} fontWeight='500' fontSize='md'>
                {formateDate(listReservation.reservation?.end)}
                </Text>
              </Box>
            </Card>
            <Card bg={bg} boxShadow ={cardShadow}>
              <Box>
                <Text fontWeight='500' color={textColorSecondary} fontSize='sm'>
                Nombre de nuit
                </Text>
                <Text color={textColorPrimary} fontWeight='500' fontSize='md'>
                {listReservation.reservation?.nuit}
                </Text>
              </Box>
            </Card>
            <Card bg={bg} boxShadow ={cardShadow}>
              <Box>
                <Text fontWeight='500' color={textColorSecondary} fontSize='sm'>
                Chambre
                </Text>
                <Text color={textColorPrimary} fontWeight='500' fontSize='md'>
                {listReservation.chambre?.intitule}
                </Text>
              </Box>
            </Card>
            <Card bg={bg} boxShadow ={cardShadow}>
              <Box>
                <Text fontWeight='500' color={textColorSecondary} fontSize='sm'>
                Nuitée
                </Text>
                <Text color={textColorPrimary} fontWeight='500' fontSize='md'>
                {(listReservation.chambre?.prix)}
                </Text>
              </Box>
            </Card>
            <Card bg={bg} boxShadow ={cardShadow}>
              <Box>
                <Text fontWeight='500' color={textColorSecondary} fontSize='sm'>
                Montant à payer
                </Text>
                <Text color={textColorPrimary} fontWeight='500' fontSize='md'>
                {montant.toLocaleString()}
                </Text>
              </Box>
            </Card>
          </SimpleGrid>
     </Card>
  );
}
