import { useState, useEffect } from 'react'
import { Table } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import { NumberToLetter } from 'convertir-nombre-lettre';
import {FaArrowLeft} from 'react-icons/fa'
import {Link} from "react-router-dom";

import './index.css';
import { getLocationId } from 'api/location';
import { getPaiementLocationId } from 'api/paiementLocation';

export default function Login() {
  const { id } = useParams();

  const boutonFacture = document.getElementById('boutonFacture')

  const handlePrint = () => {
    boutonFacture.className = "boutonFacture d-flex items-center my-3 w-100 justify-content-between d-none"
    window.print()
  }
  if(document.referrer !== document.location){
    document.location.replace(document.location)
  }

  const [listeLocation, setListeLocation] = useState([])
  const [total_en_ariary, setTotalEnAriary] = useState(0)
  
  useEffect( async ()=>{
    await getLocationId(id).then((location)=>{
      setListeLocation(location)
      console.log(location)
        setTotalEnAriary(location?.prix * location?.jour)
    })
  },[])
  const [paiementLocation ,setPaiementLocation] = useState([])
  useEffect(()=>{
    getPaiementLocationId(id).then((unPaiement)=>{
      setPaiementLocation(unPaiement[0])
    })
  },[])
  const prixRemise = (total_en_ariary * paiementLocation?.remise) / 100
  
  const dateNow = new Date(Date()).toLocaleDateString()
  const tabNumFact = dateNow.split("/")
  const numFacture = tabNumFact[0] + "" + tabNumFact[1] + "" + tabNumFact[2]
  const formateDate = (date) => {
    return new Date(date).toLocaleDateString()
  }

  return (
    <section className="printFont">

      <div>
        <ul className="boutonFacture d-flex items-center my-3 w-100 justify-content-between" id="boutonFacture">
          <li className="mx-5" id="bLi"> 
            <Link className="btn btn-print bg-danger text-light w-100" to='/admin/location' id="print"><FaArrowLeft /> </Link>
          </li>

          <li className="mx-5" id="bLi">
             <button onClick={() => {
              handlePrint()
              boutonFacture.className = "boutonFacture d-flex items-center my-3 w-100 justify-content-between"
            }}
             className="btn btn-print bg-danger text-light w-100" id="print"> Imprimer ou Exporter </button> 
          </li>

        </ul>
      </div>
      <div className="col-12 col-md-12 col-lg-12 d-flex flex-wrap justify-content-center ">
        <div className="border d-flex flex-column justify-content-between col-10 col-md-4 col-lg-4 my-3">
          <h2 className="font-bold uppercase tracking-wide text-2xl mb-3 text-center"> IMMENSE PRO </h2>
          <h5 className="text-center mb-3">Lot IVG 203 - Antanimena - 101 ANTANANARIVO</h5>
        </div>

        <div className="col-10 col-md-10 col-lg-10 d-flex justify-content-center">
          <img src="/static/media/logo.bc105c8c4e43100c8dd6.png" alt="logo" width="25%" height="25%" />
        </div>
      </div>

      <main className="m-5 d-flex flex-column justify-content-center">
              <div>
            <article className="col-12 col-md-12 col-lg-12 flex justify-center">
              <div className="my-5 flex items-end justify-end col-12 col-md-12 col-lg-10">
                <ul>
                  <li>
                    <span className="font-bold">Date : </span> {dateNow}
                  </li>
                  <li> <span className="font-bold">Facture numéro : </span> {numFacture} </li>
                  <li><span className="font-bold">DOIT</span></li>
                  <div className="border">
                    <p className="text-center p-2">Mr / Mme <b className="uppercase ">{listeLocation?.client?.nom}</b></p>
                  </div>
                </ul>
              </div>
            </article>
            <section className="col-12 col-md-12 col-lg-12 d-flex flex-wrap justify-content-center">
              <div className="col-12 col-md-12 col-lg-10">
                <Table className="table">
                  <thead className="entete">
                    <tr>
                      <th className="bdroit qte">QTE</th>
                      <th className="bdroit">DESIGNATION</th>
                      <th className="bdroit">PRIX UNITAIRE</th>
                      <th>PRIX EN ARIARY</th>
                    </tr>
                  </thead>
                  <tbody className="facture">
                    <tr>
                      <td></td>
                      <td>Location de voiture à Valiha Hôtel</td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td className="text-center">{listeLocation?.jour}</td>
                      <td>{listeLocation?.marque}</td>
                      <td className="text-center">{listeLocation?.prix}</td>
                      <td className="text-center">{total_en_ariary.toLocaleString()}</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        <ul>
                          <li>IN : {formateDate(listeLocation?.start)}</li>
                          <li>OUT : {formateDate(listeLocation?.end)}</li>
                          <li>Soit {listeLocation?.jour} jours</li>
                        </ul>
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        Remise {paiementLocation?.remise ? paiementLocation?.remise : 0} %
                      </td>
                      <td></td>
                      <td className="text-center">- {prixRemise.toLocaleString()}</td>
                    </tr>
                    <tr className="tableVide">
                      <td colSpan={2} ></td>
                      <td className="total">TOTAL EN ARIARY</td>
                      <td className="dernierTd">{(total_en_ariary - prixRemise).toLocaleString()}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            <div className="col-12 col-md-12 col-lg-10">
              <p className="prixTexte">Arretée la présente facture à la somme de <span className="uppercase">{NumberToLetter((total_en_ariary - prixRemise))} Ariary</span></p>
            </div>
            <br /><br /><br /><br /><br /><br /><br />
            </section>
          </div>
      </main>

      <div className="immensePro mb-5">
        <ul className="flex flex-wrap items-center justify-center">
          <li className="mx-1">
            <span className="font-bold">IMMENSE PRO SARLU NIF : </span> 6005554690
          </li>
          <li className="mx-1">
            <span className="font-bold"> STAT :</span> 68101 11 2021 0 10563
          </li>
          <li className="mx-1">
            <span className="font-bold"> RCS :</span>  2021B0055
          </li>
        </ul>

        <ul className="flex flex-wrap items-center justify-center">
          <li className="mx-1">
            <span className="font-bold">Adresse : </span> Lot IVG 203 Antanimena
          </li>
          <li className="mx-1">
            <span className="font-bold">  Tél :</span> +261 34 50 741 52
          </li>
          <li className="mx-1">
            <span className="font-bold"> mail :</span>  contact@valihahotel.com
          </li>
        </ul>
      </div>
    </section>
  );
}