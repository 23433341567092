import React from "react";
import { Tr, Td, Button } from "@chakra-ui/react";
import { IoCopySharp } from "react-icons/io5";
import { IoMdEye } from "react-icons/io";
import { IoIosEyeOff } from "react-icons/io";
import { FaPen } from "react-icons/fa";
import { FaTrash } from "react-icons/fa";
import { Menu, MenuButton, MenuList, MenuItem, IconButton, MenuDivider } from "@chakra-ui/react";
import { GoKebabHorizontal } from "react-icons/go";

const Section = (props) => {
  const { provided, snapshot, parentSnapshot, option, disableDrag = false, hidePrice = false } = props;

  const { onToggleVisibility, onDuplicate, onEdit, onDelete } = props;

  return (
    <Tr
      {...(disableDrag ? {} : provided.draggableProps)}
      {...provided.dragHandleProps}
      style={{
        color: option.hidden ? "rgba(50, 50, 50, 0.3)" : "",
        background: snapshot?.isDragging || parentSnapshot?.isDragging ? "#eeeeee" : "white",
        ...provided.draggableProps.style,
      }}
      ref={provided.innerRef}
      key={option._id}
    >
      <Td>{option.title}</Td>
      <Td width={"100%"} maxWidth={"100%"} whiteSpace={"pre-wrap"}>
        {option.values?.join(", ")}
      </Td>
      <Td>{option.multiple ? "Multiple" : "Une seule"}</Td>
      {!hidePrice && <Td>AR {option.price}</Td>}
      <Td paddingX={1}>
        <Button
          variant="ghost"
          mr={2}
          onClick={() => {
            onToggleVisibility(!Boolean(option.hidden));
          }}
          title="Cacher / Afficher sur le site"
        >
          {option.hidden ? <IoIosEyeOff /> : <IoMdEye />}
        </Button>
      </Td>
      <Td paddingX={1} colSpan={3}>
        <Menu>
          <MenuButton as={IconButton} aria-label="Options" icon={<GoKebabHorizontal />} variant="ghost" />
          <MenuList>
            <MenuItem
              icon={<FaPen />}
              py={2}
              onClick={onEdit}
              style={{
                color: "black",
              }}
            >
              Modifier
            </MenuItem>
            <MenuItem
              icon={<IoCopySharp />}
              py={2}
              onClick={onDuplicate}
              style={{
                color: "black",
              }}
            >
              Dupliquer
            </MenuItem>
            <MenuDivider />
            <MenuItem icon={<FaTrash color="red" />} py={2} color="red" onClick={onDelete}>
              Supprimer
            </MenuItem>
          </MenuList>
        </Menu>
      </Td>
    </Tr>
  );
};

export default Section;
