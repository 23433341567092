import {
  Link, useParams
} from "react-router-dom";
import {
  Box,
  SimpleGrid, Grid,Text
} from "@chakra-ui/react";

import General from "views/admin/reservation/components/GeneralCheckOutAvant";
import { getAllTypePaie, payer } from "../../../api/Paiement";
import { getReservation } from "../../../api/Reservation";
import React, { useEffect, useState } from "react";
export default function UserReports() {
  const [listReservation, setListReservation] = useState([]);
  const [typePaie, setTypePaie] = useState([]);
  const { id } = useParams();

  const [montant, setMontant] = useState(0);
  
  useEffect(async () => {
   await getReservation(id).then((uneReservation) => {
      setListReservation(uneReservation);
      setMontant(uneReservation.chambre?.prix * uneReservation.reservation?.nuit);
    });
    getAllTypePaie().then((typePaie) => {
      setTypePaie(typePaie);
    });
  }, []);
  

  const Enregistrer = (e) => {
    // e.preventDefault()
    const typePaie = (document.getElementById("typePaie")).value;
    const remise = (document.getElementById("remise")).value;
    payer(id, typePaie, montant,remise, new Date());

  }
  const [disabledValider, setDisabledValider] = useState(false)
  const disabledFacture = ()=>{
    const facture = document.getElementById('disabledFacture')
    facture.className = "btn btn-danger mt-2 py-2"
  }
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}
      <Grid>
        <General
          reservation={listReservation}
          nuit={listReservation.reservation?.nuit}
          montant={listReservation.chambre?.prix}
          gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
          minH='365px'
          pe='20px'
        />
      </Grid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mb='20px' mt='20px'>
        <form className="contact-validation-active" >
          <div className="row">
            <div className="col col-lg-4 col-12">
              <div className="form-field">
                <label>
                  Type de paiement :
                  <select name="typePaie" id="typePaie" className="form-control">
                    {typePaie.map((uneTypePaie) => {
                      return <option value={uneTypePaie._id} key={uneTypePaie._id}>{uneTypePaie.type}</option>
                    })};
                  </select>
                </label>
              </div>
            </div>
            <div className="col col-lg-4 col-12">
              <div className="form-field">
                <label>
                  Remise :
                  <input id="remise" className="form-control" type="number"  name="remise" placeholder="en %"  />
                </label>
              </div>
            </div>
          </div>
          <div className="submit-area d-flex mt-2">
                <div className="mx-2">
                  <button disabled={disabledValider} onClick = {
                    (e) => {
                      Enregistrer(e);
                      setDisabledValider(true)
                      disabledFacture(e)
                    }
                  } className = "btn btn-danger mt-2">Valider</button>
                </div>
                <div className="mx-2">
                  <Link to={`/facture/${listReservation.reservation?._id}`} className="btn btn-danger mt-2 py-2 pe-none" id="disabledFacture">
                    <Text fontSize='sm' fontWeight='700'>Facture </Text>
                  </Link>
                </div>
        </div>
      </form >
    </SimpleGrid>
    </Box>
  );
}