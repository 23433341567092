
import { Box,SimpleGrid, Text, useColorModeValue } from "@chakra-ui/react";
import { getReservation } from "api/Reservation";
import Card from "components/card/Card.js";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
export default function GeneralInformation(props) {
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const bg = useColorModeValue("white", "navy.700");
  const {id} = useParams()  
  const [listReservation , setListReservation] = useState([])
  const [montantTotal , setMontantTotal] = useState(0)
  useEffect(async ()=>{
      await getReservation(id).then((uneReservation)=>{
        setListReservation(uneReservation)
        setMontantTotal(uneReservation.chambre?.prix * uneReservation.reservation?.nuit)
      })
    },[])
    const formateDate = (date)=>{
      return new Date(date).toLocaleDateString()
    }
  return (
    <Card mb={{ base: "0px", "2xl": "60px" }}>
      <Text
        color={textColorPrimary}
        fontWeight='bold'
        fontSize='2xl'
        mt='10px'
        mb='4px'>
        Information du client
      </Text>
          <SimpleGrid columns='3' gap='40px' me='20px'>
            <Card bg={bg} boxShadow ={cardShadow}>
              <Box className="box">
                <Text fontWeight='500' color={textColorSecondary} fontSize='sm'> 
                Nom et prénom
                </Text>
                <Text color={textColorPrimary} fontWeight='500' fontSize='md'>
                {listReservation.reservation?.client.prenom} {listReservation.reservation?.client.nom}
                </Text>
              </Box>
            </Card>

            <Card bg={bg} boxShadow ={cardShadow}>
              <Box className="box">
                <Text fontWeight='500' color={textColorSecondary} fontSize='sm'>
                Mail
                </Text>
                <Text color={textColorPrimary} fontWeight='500' fontSize='md'>
                {listReservation.reservation?.client.mail}
                </Text>
              </Box>
            </Card>
            <Card bg={bg} boxShadow ={cardShadow}>
              <Box className="box">
                <Text fontWeight='500' color={textColorSecondary} fontSize='sm'>
                Contact
                </Text>
                <Text color={textColorPrimary} fontWeight='500' fontSize='md'>
                {listReservation.reservation?.client.numero}
                </Text>
              </Box>
            </Card>
            <Card bg={bg} boxShadow ={cardShadow}>
              <Box className="box">
                <Text fontWeight='500' color={textColorSecondary} fontSize='sm'>
                Check In
                </Text>
                <Text color={textColorPrimary} fontWeight='500' fontSize='md'>
                {formateDate(listReservation.reservation?.start)}
                </Text>
              </Box>
            </Card>
            <Card bg={bg} boxShadow ={cardShadow}>
              <Box className="box">
                <Text fontWeight='500' color={textColorSecondary} fontSize='sm'>
                Check Out
                </Text>
                <Text color={textColorPrimary} fontWeight='500' fontSize='md'>
                {formateDate(listReservation.reservation?.end)}
                </Text>
              </Box>
            </Card>
            <Card bg={bg} boxShadow ={cardShadow}>
              <Box className="box">
                <Text fontWeight='500' color={textColorSecondary} fontSize='sm'>
                Nombre de nuit
                </Text>
                <Text color={textColorPrimary} fontWeight='500' fontSize='md'>
                {listReservation.reservation?.nuit}
                </Text>
              </Box>
            </Card>
            <Card bg={bg} boxShadow ={cardShadow}>
              <Box className="box">
                <Text fontWeight='500' color={textColorSecondary} fontSize='sm'>
                Chambre
                </Text>
                <Text color={textColorPrimary} fontWeight='500' fontSize='md'>
                {listReservation.chambre?.intitule}
                </Text>
              </Box>
            </Card>
            <Card bg={bg} boxShadow ={cardShadow}>
              <Box className="box">
                <Text fontWeight='500' color={textColorSecondary} fontSize='sm'>
                Nuitée
                </Text>
                <Text color={textColorPrimary} fontWeight='500' fontSize='md'>
                {listReservation.chambre?.prix}
                </Text>
              </Box>
            </Card>
            <Card bg={bg} boxShadow ={cardShadow}>
              <Box className="box">
                <Text fontWeight='500' color={textColorSecondary} fontSize='sm'>
                Montant à payer
                </Text>
                <Text color={textColorPrimary} fontWeight='500' fontSize='md'>
                  {montantTotal}
                </Text>
              </Box>
            </Card>
          </SimpleGrid>
    </Card>
  );
}
