import React, { useState } from "react";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
  useColorModeValue,
} from "@chakra-ui/react";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { login } from "../../api/User";
import hotelImage from "../../images/admin.jpg";

export default function Login({ setToken }) {
  const textColor = useColorModeValue("navy.700", "white");
  const inputBorderColor = useColorModeValue("gray.300", "gray.600");

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleUserLogin = () => {
    const email = document.getElementById("email").value;
    const password = document.getElementById("password").value;

    if (email.length === 0 || password.length === 0) {
      return;
    }

    setError(false);
    setIsLoading(true);

    login(email, password)
      .then((res) => {
        if (res.status === "Erreur") {
          setError(true);
        } else {
          setToken(res);
          window.location.reload(false);
        }
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      height="100vh"
      bg={useColorModeValue("gray.100", "gray.800")}
    >
      <Box
        w={{ base: "100%", md: "80%" }}
        bg={useColorModeValue("white", "gray.700")}
        borderRadius="15px"
        boxShadow="md"
        overflow="hidden"
        p="40px"
        py="100px"
        maxW={{ base: "100%", md: "1200px" }}
      >
        <Flex
          direction={{ base: "column", md: "row" }}
          justifyContent="space-between"
          alignItems="center"
          >
          <Box w={{ base: "100%", md: "50%" }} mb={{ base: "20px", md: "0" }}>
            <img src={hotelImage} alt="Hotel" width="100%" height="auto" />
          </Box>
          <Flex
            direction="column"
            w={{ base: "100%", md: "45%" }}
            justifyContent="center"
            gap={8}
          >
            <Heading color={textColor} fontSize="36px" mb="20px">
              Admin Valiha Hotel
            </Heading>
            <FormControl isRequired>
              <FormLabel
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Email
              </FormLabel>
              <Input
                isRequired={true}
                id="email"
                variant="auth"
                fontSize="sm"
                type="email"
                placeholder="mail@simple.com"
                mb="24px"
                size="lg"
                borderColor={inputBorderColor}
              />
              <FormLabel
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Password
              </FormLabel>
              <InputGroup size="md">
                <Input
                  isRequired={true}
                  id="password"
                  fontSize="sm"
                  placeholder="Min. 8 characters"
                  mb="24px"
                  size="lg"
                  type={showPassword ? "text" : "password"}
                  variant="auth"
                  borderColor={inputBorderColor}
                />
                <InputRightElement>
                  <IconButton
                    aria-label={showPassword ? "Hide password" : "Show password"}
                    variant="ghost"
                    icon={
                      showPassword ? <MdVisibilityOff /> : <MdVisibility />
                    }
                    onClick={togglePasswordVisibility}
                    size="sm"
                  />
                </InputRightElement>
              </InputGroup>
              {error && (
                <Box color="red.500" mb="16px">
                  Error: Email or password invalid
                </Box>
              )}
              <button
                disabled={isLoading}
                type="button"
                className={`btn  text-white mt-2 px-3 ${
                  isLoading ? "opacity-50 cursor-not-allowed" : ""
                }`}
                onClick={handleUserLogin}
                style={{backgroundColor : 'rgba(242, 121, 53)'}}
              >
                {isLoading ? "Loading..." : "Sign in"}
              </button>
            </FormControl>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
}
