import axiosInstance from "./axios";

export const getLocationVoiture = async () => {
  return await axiosInstance
    .get("location/getLocationVoiture")
    .then((listeLocation) => {
      return listeLocation.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getAllCarLocations = async () => {
  const response = await axiosInstance.get("location")
  return  response.data 
}

export const deleteCarLocation = async (id) => {
  const response = await axiosInstance.delete("location/"+id)
  return  response.data 
}

export const getLocationBloquee = async () => {
  return await axiosInstance
    .get("/location/getLocationBloquee", {})
    .then((listeLocation) => {
      return listeLocation.data;
    });
};
//Get Location par Id
export const getLocationId = async (id) => {
  return await axiosInstance
    .get(`/location/getLocationId/` + id, {})
    .then((location) => {
      return location.data;
    })
    .catch((err) => {
      console.log(err);
    });
};
export const deleteLocation = async (id) => {
  return await axiosInstance
    .delete("location/deleteLocation/" + id, {})
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const checkOutLocation = async (id) => {
  return await axiosInstance
    .put(`/location/checkOutLocation/` + id, {})
    .then(() => {
      window.alert("Location rendue");
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getLocationNonPayer = async () => {
  return await axiosInstance
    .get("/location/getLocationNonPayer", {})
    .then((listeLocation) => {
      return listeLocation.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const bloquerVoiture = async (idVoiture, marque, start, end, raison) => {
  try {
    return await axiosInstance
      .post("/location/bloquerVoiture", {
        marque: marque,
        nom: "admin",
        prenom: "admin",
        email: "admin@gmail.com",
        numero: "00",
        idVoiture: idVoiture,
        start: start,
        end: end,
        province: "",
        trajet: "",
        raison: raison,
        jour: "",
        prix: "",
        etat: 2,
      })
      .then((resultat) => {
        alert(resultat.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {
    console.log(error);
  }
};
//Set etat =1 ou fini
export const setEtatFini = async (id) => {
  return await axiosInstance
    .put(`/location/finir/` + id, {})
    .then(() => {
      window.alert("Location fini");
    })
    .catch((err) => {
      console.log(err);
    });
};

export const LocationVoiture = async (
  marque,
  idVoiture,
  nom,
  prenom,
  email,
  numero,
  start,
  end,
  province,
  trajet,
  jour,
  prix,
  raison
) => {
  const locationVoiture = {
    marque,
    idVoiture,
    nom,
    prenom,
    email,
    numero,
    start,
    end,
    province,
    trajet,
    jour,
    prix,
    raison,
  };
  try {
    await axiosInstance
      .post("/location/locationVoiture", locationVoiture)
      .then((result) => {
        console.log(result.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {
    console.log(error);
  }
};
