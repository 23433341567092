import styles from "./siteInsight.module.css";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";
import {
  fetchInformations,
  updateInformations,
} from "../../../api/informations";
import { useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import Edit from "./Edit";

const SiteInsights = () => {
  const [informations, setInformations] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [selectedInfos, setSelectedInfos] = useState({
    title: "",
    key: "",
    value: "",
  });
  const [editLoading, setEditLoading] = useState(false);

  useEffect(() => {
    fetchInformations()
      .then((data) => {
        setInformations(data);
      })
      .catch((err) => console.log(err));
  }, []);

  function onEditButtonClicked(infos) {
    setSelectedInfos(infos);
    setShowEdit(true);
  }

  function onEditModalClose() {
    setSelectedInfos({
      title: "",
      key: "",
      value: "",
    });
    setShowEdit(false);
  }

  function onInfosEdited(infos) {
    setEditLoading(true);
    updateInformations(infos._id, infos)
      .then((updatedInfos) => {
        setInformations((oldInfos) =>
          oldInfos.map((item) => {
            if (item._id === updatedInfos._id) {
              return updatedInfos;
            }
            return item;
          })
        );
        setEditLoading(false);
        onEditModalClose();
      })
      .catch(() => {})
      .finally(() => {
        setEditLoading(false);
      });
  }

  return (
    <div className={`${styles.container}`}>
      <Edit
        show={showEdit}
        onHide={() => {
          onEditModalClose();
        }}
        editLoading={editLoading}
        onInfosEdited={onInfosEdited}
        selectedInfos={selectedInfos}
      />
      <TableContainer>
        <Table variant="simple" colorScheme="blackAlpha">
          <Thead>
            <Tr>
              <Th>Titre</Th>
              <Th>Valeur</Th>
              <Th className="text-center">Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {informations.map((info) => {
              return (
                <Tr key={info._id}>
                  <Td>{info.title}</Td>
                  <Td>AR {info.value}</Td>
                  <Td className="d-flex align-items-center justify-content-center">
                    <button
                      onClick={() => {
                        onEditButtonClicked(info);
                      }}
                    >
                      <FiEdit size={20} className="cursor-pointer" />
                    </button>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default SiteInsights;
