import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { CgDanger } from "react-icons/cg";
import { Modal } from "react-bootstrap";
import "./index.css";
// Custom components
import Card from "components/card/Card";
import {
  // annulerReservation,
  deleteReservation,
  getAllReservations,
} from "../../../../api/Reservation";
import { formateNom } from "../../../../fonction/formateNom";
import { GiCheckMark } from "react-icons/gi";
import moment from "moment";
import DeleteModal from "../DeleteModal";

// Assets
export default function ColumnsTable() {
  const [reservation, setListReservation] = useState([]);

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  useEffect(() => {
    getAllReservations()
      .then((reservations) => {
        setListReservation(reservations);
      })
      .catch((error) => {});
  }, []);



  const [detail, setDetail] = useState(false);
  const [reservationDetails, setReservationDetails] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [reservationToBeDeleted, setReservationToBeDeleted] = useState();
  const [isDeleting, setIsDeleting] = useState(false);

  function onDelete() {
    if (!reservationToBeDeleted) {
      return;
    }
    setIsDeleting(true);
    deleteReservation(reservationToBeDeleted)
      .then((deleted) => {
        const newReservation = [
          ...reservation.filter((item) => {
            return item._id !== deleted._id;
          }),
        ];
        setListReservation(newReservation);
        onDeleteModalClose();
      })
      .catch(() => {})
      .finally(() => {
        setIsDeleting(false);
      });
  }

  function onDeleteModalClose() {
    setShowDeleteModal(false);
    setReservationToBeDeleted(null);
  }

  function onDeleteButtonClicked(reservationID) {
    if (!reservationID) {
      return;
    }
    setReservationToBeDeleted(reservationID);
    setShowDeleteModal(true);
  }

  return (
    <Card direction="column" w="100%" px="0px" className="tableReservation">


      <DeleteModal
        show={showDeleteModal}
        loading={isDeleting}
        onDelete={onDelete}
        onHide={() => {
          onDeleteModalClose();
        }}
      />

      {/* DETAIL CHAMBRE */}
      <Modal
        show={detail}
        onHide={() => setDetail(false)}
        scrollable={true}
        backdrop="static"
        keyboard={false}
        centered
        className="border-0"
        size="lg"
      >
        <Modal.Header
          className="border-0"
          closeButton
          onClick={() => {
            setDetail(false);
          }}
        >
          <div className="w-100 d-flex justify-content-center">
            <p className="titreModal text-dager text-center">
              Détails de la réservation <br /> du{" "}
              {moment(reservationDetails?.start).format("DD-MM-YYYY")} au{" "}
              {moment(reservationDetails?.end).format("DD-MM-YYYY")}
            </p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="location form-group row" id="location">
            <div className="form-group col-12 col-md-12 col-lg-12 d-flex flex-wrap justify-content-around">
              <div className="col-12 col-md-12 col-lg-12 d-flex justify-content-evenly my-2">
                <label htmlFor="label-nom" className="col-3 col-form-label">
                  Nom :{" "}
                </label>
                <div className="form-field col-8 col-md-8 col-lg-8">
                  <input
                    id="label-nom"
                    type="text"
                    className="form-control py-2"
                    placeholder={formateNom(
                      reservationDetails?.client.lastName
                    )}
                    disabled
                  />
                </div>
              </div>
              <div className="col-12 col-md-12 col-lg-12 d-flex justify-content-evenly my-2">
                <label htmlFor="label-prenom" className="col-3 col-form-label">
                  Prénom :{" "}
                </label>
                <div className="form-field col-8 col-md-8 col-lg-8">
                  <input
                    type="text"
                    id="label-prenom"
                    className="form-control py-2"
                    placeholder={reservationDetails?.client.firstName}
                    disabled
                  />
                </div>
              </div>
              <div className="col-12 col-md-12 col-lg-12 d-flex justify-content-evenly my-2">
                <label htmlFor="label-email" className="col-3 col-form-label">
                  Email :{" "}
                </label>
                <div className="form-field col-8 col-md-8 col-lg-8">
                  <input
                    type="text"
                    id="label-email"
                    className="form-control py-2"
                    placeholder={reservationDetails?.client.email}
                    disabled
                  />
                </div>
              </div>
              <div className="col-12 col-md-12 col-lg-12 d-flex justify-content-evenly my-2">
                <label htmlFor="label-numero" className="col-3 col-form-label">
                  Numéro :{" "}
                </label>
                <div className="form-field col-8 col-md-8 col-lg-8">
                  <input
                    type="text"
                    id="label-numero"
                    className="form-control py-2"
                    placeholder={"+" + reservationDetails?.client.phoneNumber}
                    disabled
                  />
                </div>
              </div>
              <div className="col-12 col-md-12 col-lg-12 d-flex justify-content-evenly my-2">
                <label htmlFor="label-chambre" className="col-3 col-form-label">
                  Chambre :{" "}
                </label>
                <div className="form-field col-8 col-md-8 col-lg-8">
                  <input
                    type="text"
                    id="label-chambre"
                    className="form-control py-2"
                    placeholder={reservationDetails?.idChambre[0].intitule}
                    disabled
                  />
                </div>
              </div>
              <div className="col-12 col-md-12 col-lg-12 d-flex justify-content-evenly my-2">
                <label htmlFor="label-nuit" className="col-3 col-form-label">
                  Nombre de nuit :{" "}
                </label>
                <div className="form-field col-8 col-md-8 col-lg-8">
                  <input
                    type="text"
                    id="label-nuit"
                    className="form-control py-2"
                    placeholder={reservationDetails?.nuit}
                    disabled
                  />
                </div>
              </div>

              {reservationDetails?.breakfasts[0].numPeopleToEatBreakfast > 0 ? (
                <div className="col-12 col-md-12 col-lg-12 pl-5">
                  <label
                    htmlFor="label-petitdej"
                    className="col-3 col-form-label"
                  >
                    Petit déjeuner :{" "}
                  </label>
                  <div
                    id="label-petitdej"
                    className="col-12 col-md-12 col-lg-12 d-flex justify-content-evenly my-2"
                  >
                    <label htmlFor="label-pax" className="col-3 col-form-label">
                      Nombre de pax :{" "}
                    </label>
                    <div className="form-field col-8 col-md-8 col-lg-8">
                      <input
                        type="text"
                        id="label-pax"
                        className="form-control py-2"
                        placeholder={
                          reservationDetails?.breakfasts[0]
                            .numPeopleToEatBreakfast
                        }
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-12 col-lg-12 d-flex justify-content-evenly my-2">
                    <label
                      htmlFor="label-totalpetitdej"
                      className="col-3 col-form-label"
                    >
                      Prix total du petit déjeuner :{" "}
                    </label>
                    <div className="form-field col-8 col-md-8 col-lg-8">
                      <input
                        type="text"
                        id="label-totalpetitdej"
                        className="form-control py-2"
                        placeholder={
                          "Ar " + reservationDetails?.breakfasts[0].totalPrice
                        }
                        disabled
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="col-12 col-md-12 col-lg-12 d-flex justify-content-evenly my-2">
                  <label
                    htmlFor="label-nopetitdej"
                    className="col-3 col-form-label"
                  >
                    Petit déjeuner :{" "}
                  </label>
                  <div className="form-field col-8 col-md-8 col-lg-8">
                    <input
                      type="text"
                      id="label-nopetitdej"
                      className="form-control py-2"
                      placeholder="Pas de petit déjeuner"
                      disabled
                    />
                  </div>
                </div>
              )}

              {reservationDetails?.navettes.length > 0 ? (
                <div className="col-12 col-md-12 col-lg-12 pl-5">
                  Navette
                  <div className="col-12 col-md-12 col-lg-12 d-flex justify-content-evenly my-2">
                    <label
                      htmlFor="label-navvol"
                      className="col-3 col-form-label"
                    >
                      Nom du vol :{" "}
                    </label>
                    <div className="form-field col-8 col-md-8 col-lg-8">
                      <input
                        type="text"
                        id="label-navvol"
                        className="form-control py-2"
                        placeholder={reservationDetails?.navettes[0].nom}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-12 col-lg-12 d-flex justify-content-evenly my-2">
                    <label
                      htmlFor="label-numvol"
                      className="col-3 col-form-label"
                    >
                      Numero de vol :{" "}
                    </label>
                    <div className="form-field col-8 col-md-8 col-lg-8">
                      <input
                        type="text"
                        id="label-numvol"
                        className="form-control py-2"
                        placeholder={reservationDetails?.navettes[0].numeroVol}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-12 col-lg-12 d-flex justify-content-evenly my-2">
                    <label
                      htmlFor="label-lieunav"
                      className="col-3 col-form-label"
                    >
                      Lieu de la navette :{" "}
                    </label>
                    <div className="form-field col-8 col-md-8 col-lg-8">
                      <input
                        type="text"
                        id="label-lieunav"
                        className="form-control py-2"
                        placeholder={reservationDetails?.navettes[0].direction}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-12 col-lg-12 d-flex justify-content-evenly my-2">
                    <label
                      htmlFor="label-heureArriveNav"
                      className="col-3 col-form-label"
                    >
                      Heure d'arrivée :{" "}
                    </label>
                    <div className="form-field col-8 col-md-8 col-lg-8">
                      <input
                        type="text"
                        id="label-heureArriveNav"
                        className="form-control py-2"
                        placeholder={reservationDetails?.navettes[0].heure}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="col-12 col-md-12 col-lg-12 d-flex justify-content-evenly my-2">
                  <label htmlFor="label-noNav" className="col-3 col-form-label">
                    Navette :{" "}
                  </label>
                  <div className="form-field col-8 col-md-8 col-lg-8">
                    <input
                      type="text"
                      id="label-noNav"
                      className="form-control py-2"
                      placeholder="Pas de navette"
                      disabled
                    />
                  </div>
                </div>
              )}

              <div className="col-12 col-md-12 col-lg-12 d-flex justify-content-evenly my-2">
                <label htmlFor="label-parking" className="col-3 col-form-label">
                  Parking :{" "}
                </label>
                <div className="form-field col-8 col-md-8 col-lg-8">
                  {reservationDetails?.parking &&
                  reservationDetails?.parking === "true" ? (
                    <input
                      type="text"
                      id="label-parking"
                      className="form-control py-2"
                      placeholder="avec"
                      disabled
                    />
                  ) : (
                    <input
                      type="text"
                      id="label-parking"
                      className="form-control py-2"
                      placeholder="sans"
                      disabled
                    />
                  )}
                </div>
              </div>
              <div className="col-12 col-md-12 col-lg-12 d-flex justify-content-evenly my-2">
                <label htmlFor="label-note" className="col-3 col-form-label">
                  Remarques :{" "}
                </label>
                <div className="form-field col-8 col-md-8 col-lg-8">
                  <input
                    type="text"
                    id="label-note"
                    className="form-control py-2"
                    placeholder={reservationDetails?.note ?? "Pas de remarques"}
                    disabled
                  />
                </div>
              </div>
              <div className="col-12 col-md-12 col-lg-12 d-flex justify-content-evenly my-2">
                <label
                  htmlFor="label-totalPrice"
                  className="col-3 col-form-label"
                >
                  Montant total :{" "}
                </label>
                <div className="form-field col-8 col-md-8 col-lg-8">
                  <input
                    type="text"
                    id="label-totalPrice"
                    className="form-control py-2"
                    placeholder={"Ar " + reservationDetails?.montantTotal}
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
          {/* DETAIL CHAMBRE */}
        </Modal.Body>
      </Modal>
      <Table
        variant="striped"
        color="gray.500"
        mb="24px"
        className="table table-bordered"
      >
        <Thead>
          <Tr>
            <Th pe="5px" borderColor={borderColor}>
              <Flex
                justify="space-between"
                align="center"
                fontSize={{ sm: "10px", lg: "12px" }}
                color="gray.400"
              >
                {" "}
              </Flex>
            </Th>
            <Th pe="300px" borderColor={borderColor}>
              Nom et prénom
              <Flex
                justify="space-between"
                align="center"
                fontSize={{ sm: "10px", lg: "12px" }}
                color="gray.400"
              >
                {" "}
              </Flex>
            </Th>
            <Th pe="100px" borderColor={borderColor}>
              Chambre
              <Flex
                justify="space-between"
                align="center"
                fontSize={{ sm: "10px", lg: "12px" }}
                color="gray.400"
              >
                {" "}
              </Flex>
            </Th>
            <Th pe="100px" borderColor={borderColor}>
              Start
              <Flex
                justify="space-between"
                align="center"
                fontSize={{ sm: "10px", lg: "12px" }}
                color="gray.400"
              >
                {" "}
              </Flex>
            </Th>
            <Th pe="100px" borderColor={borderColor}>
              End
              <Flex
                justify="space-between"
                align="center"
                fontSize={{ sm: "10px", lg: "12px" }}
                color="gray.400"
              >
                {" "}
              </Flex>
            </Th>
            <Th pe="100px" borderColor={borderColor}>
              Navette
              <Flex
                justify="space-between"
                align="center"
                fontSize={{ sm: "10px", lg: "12px" }}
                color="gray.400"
              >
                {" "}
              </Flex>
            </Th>
            <Th pe="100px" borderColor={borderColor}>
              Petit déjeuner
              <Flex
                justify="space-between"
                align="center"
                fontSize={{ sm: "10px", lg: "12px" }}
                color="gray.400"
              >
                {" "}
              </Flex>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {reservation.map((uneReservation) => {
            const [annee, mois, jour] = uneReservation?.start
              .split("T")[0]
              .split("-");
            const dateIn = [jour, mois, annee].join("-");
            const [annee2, mois2, jour2] = uneReservation?.end
              .split("T")[0]
              .split("-");
            const dateOut = [jour2, mois2, annee2].join("-");
            return (
              <Tr key={uneReservation?._id}>
                <Td
                  fontSize={{ sm: "14px" }}
                  maxH="30px !important"
                  py="8px"
                  minW={{ sm: "150px", md: "200px", lg: "auto" }}
                  borderColor="transparent"
                >
                  <button
                    color={textColor}
                    fontSize="sm"
                    fontWeight="700"
                    title="Détails de cette location"
                    className="bDetail"
                    onClick={() => {
                      setDetail(true);
                      setReservationDetails(uneReservation);
                    }}
                  >
                    <CgDanger />
                  </button>
                </Td>

                <Td
                  fontSize={{ sm: "14px" }}
                  maxH="30px !important"
                  py="8px"
                  minW={{ sm: "150px", md: "200px", lg: "auto" }}
                  borderColor="transparent"
                >
                  <Text color={textColor} fontSize="sm" fontWeight="700">
                    {formateNom(uneReservation?.client?.lastName) +
                      " " +
                      uneReservation?.client?.firstName}
                  </Text>
                </Td>
                <Td
                  fontSize={{ sm: "14px" }}
                  maxH="30px !important"
                  py="8px"
                  minW={{ sm: "150px", md: "200px", lg: "auto" }}
                  borderColor="transparent"
                >
                  <Text color={textColor} fontSize="sm" fontWeight="700">
                    {uneReservation?.idChambre[0]?.intitule}
                  </Text>
                </Td>
                <Td
                  fontSize={{ sm: "14px" }}
                  maxH="30px !important"
                  py="8px"
                  minW={{ sm: "150px", md: "200px", lg: "auto" }}
                  borderColor="transparent"
                >
                  <Text color={textColor} fontSize="sm" fontWeight="700">
                    {dateIn}
                  </Text>
                </Td>
                <Td
                  fontSize={{ sm: "14px" }}
                  maxH="30px !important"
                  py="8px"
                  minW={{ sm: "150px", md: "200px", lg: "auto" }}
                  borderColor="transparent"
                >
                  <Text color={textColor} fontSize="sm" fontWeight="700">
                    {dateOut}
                  </Text>
                </Td>

                <Td
                  fontSize={{ sm: "14px" }}
                  maxH="30px !important"
                  py="8px"
                  minW={{ sm: "150px", md: "200px", lg: "auto" }}
                  borderColor="transparent"
                >
                  <Text
                    color={textColor}
                    fontSize="sm"
                    fontWeight="700"
                    display="flex"
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    {uneReservation?.navettes.length > 0 ? (
                      <GiCheckMark />
                    ) : (
                      "-"
                    )}
                  </Text>
                </Td>

                <Td
                  fontSize={{ sm: "14px" }}
                  maxH="30px !important"
                  py="8px"
                  minW={{ sm: "150px", md: "200px", lg: "auto" }}
                  borderColor="transparent"
                >
                  <Text
                    color={textColor}
                    fontSize="sm"
                    fontWeight="700"
                    display="flex"
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    {uneReservation?.breakfasts[0].numPeopleToEatBreakfast ? (
                      <GiCheckMark />
                    ) : (
                      "-"
                    )}
                  </Text>
                </Td>
                <Td
                  fontSize={{ sm: "10px" }}
                  maxH="20px !important"
                  py="4px"
                  minW={{ sm: "100px", md: "100px", lg: "auto" }}
                  borderColor="transparent"
                >
                  <button
                    onClick={() => {
                      onDeleteButtonClicked(uneReservation?._id);
                    }}
                    className="theme-btn bValider"
                  >
                    <Text
                      fontSize="sm"
                      fontWeight="700"
                      className="bg-danger p-2 text-light"
                      style={{ borderRadius: '3px' }}
                    >
                      Supprimer{" "}
                    </Text>
                  </button>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Card>
  );
}
