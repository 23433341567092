import { Flex, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {BsTrash} from "react-icons/bs"
import { Link } from "react-router-dom";
import {CgDanger} from "react-icons/cg"
import {Modal} from 'react-bootstrap'
import './index.css'
// Custom components
import Card from "components/card/Card";
import { getLocationVoiture, deleteLocation } from "api/location";
import { checkOutLocation } from "api/location";

// Assets
export default function ColumnsTable() {

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const [listeLocation, setListLocation] = useState([])
  useEffect(() => {
    getLocationVoiture().then((listeLocation) => {
      const newListLocation = listeLocation.filter(location => location?.etat === 0)
      setListLocation(newListLocation)
    })
  }, [])

  const formateDate = (date) => {
    return (new Date(date)).toLocaleDateString()
  }

  const formateName = (nom) => {
    return (nom && nom.toUpperCase())
  }
  const supprimerLocation = (id) => {
    if (window.confirm("Voulez-vous vraiment annuler cette réservation de voiture ?")) {
      deleteLocation(id)
      const newListeLocation = listeLocation.filter((newList) => newList?._id !== id);
      setListLocation(newListeLocation)
    }
    else {
      return
    }
  }
  const rendreVoiture = (id, marque) => {
    if (window.confirm("Voulez-vous rendre la voiture de marque " + marque + "?")) {
      checkOutLocation(id)
      const newListeLocation = listeLocation.filter((newList) => newList?._id !== id)
      setListLocation(newListeLocation)
    }
  }

  const [selectedValue, setSelectedValue] = useState('')
  useEffect(() => {
    liste()
  }, [selectedValue])
  const liste = () => {
    const choixListe = document.getElementById("choixListe")?.value
    setSelectedValue(choixListe)
    if (selectedValue === "chambre") {
      window.location.href = "/#/admin/occupe"
    }
    else if (selectedValue === "location") {
      window.location.href = "/#/admin/location"
    }
  }
  if (window.location.href !== '/#/admin/location') {
    window.location.href = '/#/admin/location'
  }
  const [detail, setDetail] = useState(false)
  const [valDetail, setValDetail] = useState({
    marque : '',
    nom : '',
    prenom : '',
    email: '',
    numero : '',
    start : '',
    end : '',
    destination : '',
    trajet : ''
  })
  return (
    <Card
      direction='column'
      w='100%'
      px='0px'
      // overflowX={{ display: "none" }}>
      className='tableLocation'>
      <select 
          name="choixListe" 
          id="choixListe" 
          onClick={() => liste()} 
          className="selectChoix my-3 p-2"
          style={{ border: "1px solid darkGray" }}
      > 
        <option value="" hidden>Réservation / Location de voiture</option>
        <option value="chambre" className=''>Liste de réservation de chambre</option>
        <option value="location" className=''>Liste de location de voiture</option>
      </select>
      <Flex px='25px' justify='space-between' mb='10px' align='center'>
        <Text color={textColor} fontSize='22px' fontWeight='700' lineHeight='100%'>
          Liste de location de voiture
        </Text>
      </Flex>
      
          {/* DETAIL LOCATION */}

      <Modal show={detail} onHide={() => setDetail(false)} scrollable={true} backdrop='static' keyboard={false} centered className="border-0" size="md">
        <Modal.Header className='border-0' closeButton onClick={() => {
          setDetail(false)
        }}>
          <div className="w-100 d-flex justify-content-center">
            <p className="titreModal text-danger">Détails de la location </p>
          </div>
        </Modal.Header>
        <Modal.Body>

          <div className="location form-group row" id="location">
            <div className="form-group col-12 col-md-12 col-lg-12 d-flex flex-wrap justify-content-around">
              
              <div className="col-12 col-md-12 col-lg-12 d-flex justify-content-evenly my-2">
                <label htmlFor="" className="col-3 col-form-label">Marque : </label>
                <div className="form-field col-8 col-md-8 col-lg-8">
                <input type="text" className="form-control py-2" placeholder={valDetail.marque} disabled/>
                </div>
              </div>
              <div className="col-12 col-md-12 col-lg-12 d-flex justify-content-evenly my-2">
                <label htmlFor="" className="col-3 col-form-label">Nom: </label>
                <div className="form-field col-8 col-md-8 col-lg-8">
                  <input type="text" id="startLocation" className="form-control py-2" placeholder={valDetail.nom} disabled/>
                </div>
              </div>
              <div className="col-12 col-md-12 col-lg-12 d-flex justify-content-evenly my-2">
                <label htmlFor="" className="col-3 col-form-label">Prénom : </label>
                <div className="form-field col-8 col-md-8 col-lg-8">
                  <input type="text" id="endLocation" className="form-control py-2" placeholder={valDetail.prenom} disabled/>
                </div>
              </div>
              <div className="col-12 col-md-12 col-lg-12 d-flex justify-content-evenly my-2">
                <label htmlFor="" className="col-3 col-form-label">Email : </label>
                <div className="form-field col-8 col-md-8 col-lg-8">
                  <input type="text" id="endLocation" className="form-control py-2" placeholder={valDetail.email} disabled/>
                </div>
              </div>
              <div className="col-12 col-md-12 col-lg-12 d-flex justify-content-evenly my-2">
                <label htmlFor="" className="col-3 col-form-label">Numéro : </label>
                <div className="form-field col-8 col-md-8 col-lg-8">
                  <input type="text" id="endLocation" className="form-control py-2" placeholder={valDetail.numero} disabled/>
                </div>
              </div>
              <div className="col-12 col-md-12 col-lg-12 d-flex justify-content-evenly my-2">
                <label htmlFor="" className="col-3 col-form-label">Check In : </label>
                <div className="form-field col-8 col-md-8 col-lg-8">
                  <input type="text" id="endLocation" className="form-control py-2" placeholder={formateDate(valDetail.start)} disabled/>
                </div>
              </div>
              <div className="col-12 col-md-12 col-lg-12 d-flex justify-content-evenly my-2">
                <label htmlFor="" className="col-3 col-form-label">Check Out : </label>
                <div className="form-field col-8 col-md-8 col-lg-8">
                  <input type="text" id="endLocation" className="form-control py-2" placeholder={formateDate(valDetail.end)} disabled/>
                </div>
              </div>
              <div className="col-12 col-md-12 col-lg-12 d-flex justify-content-evenly my-2">
                <label htmlFor="" className="col-3 col-form-label">Province : </label>
                <div className="form-field col-8 col-md-8 col-lg-8">
                  <input type="text" id="endLocation" className="form-control py-2" placeholder={valDetail.destination} disabled />
                </div>
              </div>
              <div className="col-12 col-md-12 col-lg-12 d-flex justify-content-evenly my-2">
                <label htmlFor="" className="col-3 col-form-label">Trajet : </label>
                <div className="form-field col-8 col-md-8 col-lg-8">
                  <input type="text" id="endLocation" className="form-control py-2" placeholder={valDetail.trajet} disabled />
                </div>
              </div>
            </div>
          </div>
          {/* DETAIL LOCATION */}
        </Modal.Body>
      </Modal>
      
      <Table variant='striped' color='gray.500' mb='24px'>
        <Thead>
          <Tr>
            <Th pe='10px' borderColor={borderColor}>
              <Flex justify='space-between' align='center' fontSize={{ sm: "10px", lg: "12px" }} color='gray.400'> </Flex>
            </Th>
            <Th pe='150px' borderColor={borderColor}>Marque du véhicule
              <Flex justify='space-between' align='center' fontSize={{ sm: "10px", lg: "12px" }} color='gray.400'> </Flex>
            </Th>
            <Th pe='200px' borderColor={borderColor}>Nom et prénom
              <Flex justify='space-between' align='center' fontSize={{ sm: "10px", lg: "12px" }} color='gray.400'> </Flex>
            </Th>
            <Th pe='10px' borderColor={borderColor}>Start
              <Flex justify='space-between' align='center' fontSize={{ sm: "10px", lg: "12px" }} color='gray.400'> </Flex>
            </Th>
            <Th pe='10px' borderColor={borderColor}>End
              <Flex justify='space-between' align='center' fontSize={{ sm: "10px", lg: "12px" }} color='gray.400'> </Flex>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {listeLocation.length > 0 && listeLocation.map((location) => {
            return (
              <Tr key={location?._id}>

                <Td fontSize={{ sm: "14px" }} maxH='30px !important' py='8px' minW={{ sm: "150px", md: "200px", lg: "auto" }} borderColor='transparent'>
                  <button color={textColor} fontSize='sm' fontWeight='700' title='Détails de cette location' className="bDetail" onClick={()=>{
                    setDetail(true)
                    setValDetail({
                      marque : location?.marque,
                      nom : formateName(location?.client?.nom),
                      prenom : location?.client?.prenom,
                      email : location?.client?.email,
                      numero : location?.client?.numero,
                      start : location?.start, 
                      end : location?.end,
                      destination : location?.province,
                      trajet : location?.trajet
                    })
                  }}><CgDanger /></button>
                </Td>
                <Td fontSize={{ sm: "14px" }} maxH='30px !important' py='8px' minW={{ sm: "150px", md: "200px", lg: "auto" }} borderColor='transparent'>
                  <Text color={textColor} fontSize='sm' fontWeight='700'>  {location?.marque} </Text>
                </Td>
                <Td fontSize={{ sm: "14px" }} maxH='30px !important' py='8px' minW={{ sm: "150px", md: "200px", lg: "auto" }} borderColor='transparent'>
                  <Text color={textColor} fontSize='sm' fontWeight='700'>  {`${formateName(location?.client?.nom)} ${location?.client?.prenom}`} </Text>
                </Td>
                <Td fontSize={{ sm: "10px" }} maxH='30px !important' py='8px' minW={{ sm: "150px", md: "200px", lg: "auto" }} borderColor='transparent'>
                  <Text color={textColor} fontSize='sm' fontWeight='700'> {formateDate(location?.start)} </Text>
                </Td>
                <Td fontSize={{ sm: "10px" }} maxH='30px !important' py='8px' minW={{ sm: "150px", md: "200px", lg: "auto" }} borderColor='transparent'>
                  <Text color={textColor} fontSize='sm' fontWeight='700'> {formateDate(location?.end)} </Text>
                </Td>
                {location?.etatPaie <= 0 &&
                  <Td fontSize={{ sm: "3px" }} maxH='20px !important' py='4px' minW={{ sm: "100px", md: "100px", lg: "auto" }} borderColor='transparent'>
                    <Link onClick={() => {
                      supprimerLocation(location?._id)
                    }} to="/admin/location" className="theme-btn bValider">
                      <Text fontSize='sm' fontWeight='700' id="disabledbAnnuler" className="bg-danger rounded p-2 text-light"> Annuler </Text>
                    </Link>
                  </Td>
                }
                {location?.etatPaie > 0 &&
                  <Td fontSize={{ sm: "3px" }} maxH='20px !important' py='4px' minW={{ sm: "100px", md: "100px", lg: "auto" }} borderColor='transparent'>
                    <button className="theme-btn bValider" disabled title="Vous ne pouvez plus annuler après paiement">
                      <Text fontSize='sm' fontWeight='700' id="disabledbAnnuler" className="bg-danger rounded p-2 text-light"> Annuler </Text>
                    </button>
                  </Td>
                }
                {location?.etatPaie > 0 &&
                  <Td fontSize={{ sm: "10px" }} maxH='20px !important' py='4px' minW={{ sm: "100px", md: "100px", lg: "auto" }} borderColor='transparent'>
                    <Link to={`/factureLocation/${location?._id}`} className="" id="bFacture">
                      <Text fontSize='sm' fontWeight='700' className="bg-danger rounded p-2 text-light">Facture </Text>
                    </Link>
                  </Td>
                }
                {location?.etatPaie <= 0 &&
                  <Td fontSize={{ sm: "10px" }} maxH='20px !important' py='4px' minW={{ sm: "100px", md: "100px", lg: "auto" }} borderColor='transparent'>
                    <Link to={`/admin/payerLocation/${location?._id}`} className="theme-btn bValider">
                      <Text fontSize='sm' fontWeight='700' className="bg-danger rounded px-3 py-2 text-light">  Payer </Text>
                    </Link>
                  </Td>
                }
                {location?.etatPaie > 0 &&
                  <Td fontSize={{ sm: "10px" }} maxH='20px !important' py='4px' minW={{ sm: "100px", md: "175px", lg: "175px" }} borderColor='transparent'>
                    <Link onClick={() => {
                      rendreVoiture(location?._id, location?.marque);
                    }} to={`/admin/location`} className="bg-danger">
                      <Text fontSize='sm' fontWeight='700' className="rounded bg-danger px-1 py-2 text-light">Rendre la voiture</Text>
                    </Link>
                  </Td>
                }
                {location?.etatPaie <= 0 &&
                  <Td fontSize={{ sm: "10px" }} maxH='20px !important' py='4px' minW={{ sm: "100px", md: "175px", lg: "175px" }} borderColor='transparent'>
                    <Link onClick={() => {
                    }} to={`/admin/rendreVoiture/${location?._id}`}>
                      <Text fontSize='sm' fontWeight='700' className="rounded bg-danger px-1 py-2 text-light">Rendre la voiture</Text>
                    </Link>
                  </Td>
                }
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Card>
  );
}
