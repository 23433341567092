import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { FormControl, FormLabel, Input, Button, Textarea } from "@chakra-ui/react";
import * as breakfastService from "../../api/Breakfast";

export default function AddMenuModal({ show, onHide, addMenu, breakfastToUpdate, updateMenu, isExtra }) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState(0);

  const resetAllFields = () => {
    setTitle("");
    setDescription("");
    setPrice(0);
  };

  useEffect(() => {
    if (breakfastToUpdate) {
      setTitle(breakfastToUpdate.title);
      setDescription(breakfastToUpdate.description);
      setPrice(breakfastToUpdate.price);
    }

    return () => {
      resetAllFields();
    };
  }, [breakfastToUpdate]);

  const handleSubmit = async ({ title, description, price }) => {
    setLoading(true);
    try {
      let menu;
      if (breakfastToUpdate) {
        // updating
        menu = await breakfastService.updateMenu({
          breakfastId: breakfastToUpdate._id,
          title,
          description,
          price: parseInt(price) || 0,
        });
        updateMenu(breakfastToUpdate._id, menu, isExtra);
      } else {
        // creating
        menu = await breakfastService.createMenu({ title, description, price: parseInt(price) || 0 });
        addMenu(menu);
      }
      resetAllFields();
      onHide();
    } catch {
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal show={show} onHide={onHide} centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{breakfastToUpdate === null ? "Créer un menu" : "Modifier le menu"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (title.length > 1) {
                handleSubmit({
                  title,
                  description,
                  price,
                });
              }
            }}
          >
            <FormControl>
              <FormLabel>Titre</FormLabel>
              <Input type="text" placeholder="Ex: EGG ESPAGNOL" required value={title} onChange={(e) => setTitle(e.target.value)} />
            </FormControl>
            {!isExtra && (
              <FormControl mt={5}>
                <FormLabel>Prix</FormLabel>
                <Input type="number" required value={price || 0} onChange={(e) => setPrice(e.target.value)} />
              </FormControl>
            )}
            <FormControl mt={5}>
              <FormLabel>Description (optionnel)</FormLabel>
              <Textarea
                type="text"
                placeholder="Écrivez ici ce que contient le menu... (Ex: Pomme de terre, poivron, saucisse)"
                value={description}
                rows={6}
                onChange={(e) => setDescription(e.target.value)}
              />
            </FormControl>
            <div className="mt-5 w-100 d-flex justify-content-end">
              <Button type="button" marginX={3} colorScheme="blue" variant="ghost" onClick={onHide}>
                Annuler
              </Button>
              <Button isLoading={loading} type="submit" colorScheme="blue" variant="solid">
                {breakfastToUpdate === null ? "Créer" : "Modifier"}
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
