import axiosInstance from "./axios";
import moment from "moment";

//Get Reservation par Id
export const getReservation = async (id) => {
  return await axiosInstance
    .get(`/reservation/getReservation/` + id, {})
    .then((reservation) => {
      return reservation.data;
    })
    .catch((err) => {
      console.log(err);
    });
};
//GETReservation Après now
export const getReservationNow = async () => {
  return await axiosInstance
    .get("/reservation/getReservationNow")
    .then((listChambre) => {
      return listChambre.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

// GET_Reservation-entre-2-date
export const getReservation2Date = async (start, end) => {
  return await axiosInstance
    .get("/reservation/getReservation2Date", {
      params: {
        checkIn: moment(start).toISOString(),
        checkOut: moment(end).toISOString(),
      },
    })
    .then((listeReservation) => {
      return listeReservation.data;
    })
    .catch((err) => {
      console.log(err);
    });
};
export const getReservationNonPayer = async () => {
  return await axiosInstance
    .get("/reservation/getReservationNonPayer", {})
    .then((listeReservation) => {
      return listeReservation.data;
    })
    .catch((err) => {
      console.log(err);
    });
};
export const getReservationOccupe = async () => {
  return await axiosInstance
    .get("/reservation/getReservationOccupe", {})
    .then((listeReservation) => {
      return listeReservation.data;
    });
};
export const getReservationBloque = async () => {
  return await axiosInstance
    .get("/reservation/getReservationBloque", {})
    .then((listeReservation) => {
      return listeReservation.data;
    });
};
//Set etat =1 ou fini
export const setEtatFini = async (id) => {
  return await axiosInstance
    .put(`/reservation/finir/` + id, {})
    .then(() => {
      window.alert("Réservation fini");
    })
    .catch((err) => {
      console.log(err);
    });
};

export const annulerReservation = async (id) => {
  return await axiosInstance
    .put(`/reservation/annulerReservation/` + id, {})
    .then(() => {
      window.alert("Réservation Chambre annulée");
    })
    .catch((error) => {
      console.log(error);
    });
};

export const faireReservation = async (
  nom,
  prenom,
  email,
  numero,
  idChambre,
  nuit,
  start,
  end,
  chambreDetails,
  montantTotal,
  cours,
  nuitEuro,
  parking,
  navette,
  heure,
  remarque,
  raison
) => {
  try {
    return await axiosInstance
      .post("/reservation/reserver", {
        nom: nom,
        prenom: prenom,
        email: email,
        numero: numero,
        idChambre: idChambre,
        nuit: nuit,
        start: start,
        end: end,
        etat: 0,
        chambre: chambreDetails,
        montantTotal: montantTotal,
        cours: cours,
        nuitEuro: nuitEuro,
        parking: parking,
        navette: navette,
        heure: heure,
        remarque: remarque,
        raison: raison,
      })
      .then((resultat) => {
        if (resultat.data.message !== undefined) {
          alert(resultat.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {
    console.log(error);
  }
};
export const bloquerChambre = async (idChambre, start, end, raison) => {
  try {
    return await axiosInstance
      .post("/reservation/bloquer", {
        nom: "admin",
        prenom: "admin",
        email: "admin@gmail.com",
        numero: "00",
        idChambre: idChambre,
        start: start,
        end: end,
        raison: raison,
        etat: 2,
      })
      .then((resultat) => {
        alert(resultat.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {
    console.log(error);
  }
};

export async function getAllReservations() {
  const response = await axiosInstance.get("/reservation");
  return response.data;
}

export async function deleteReservation(id) {
  const response = await axiosInstance.delete("/reservation/" + id);
  return response.data;
}
