import React, { useState, useEffect } from "react";
import { useDisclosure, Table, Thead, Tbody, Box, Tr, Th, Td, TableCaption, TableContainer } from "@chakra-ui/react";
import moment from "moment";
import ViewModal from "./ViewModal";
import { getAllCarLocations, deleteCarLocation } from "api/location";

const ReservationList = () => {
  const [carLocation, setCarLocation] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [carLocations, setCarLocations] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const carLocationsData = await getAllCarLocations();
        setCarLocations(carLocationsData);
      } catch {}
    }

    fetchData();
  }, []);

  async function onDelete(id) {
    await deleteCarLocation(id);
    setCarLocations((prev) => prev.filter((location) => location._id !== id));
  }

  return (
    <>
      <ViewModal
        isOpen={isOpen}
        onOpen={onOpen}
        onDelete={onDelete}
        onClose={() => {
          setCarLocation(undefined);
          onClose();
        }}
        carLocation={carLocation}
      />
      <TableContainer mt={3} border={"1px solid #eee"} borderRadius={"10px"} padding={2}>
        <Table>
          <TableCaption>Liste des reservations de voiture</TableCaption>
          <Thead bg={"gray.200"}>
            <Tr>
              <Th>Client</Th>
              <Th>Voiture</Th>
              <Th>Debut</Th>
              <Th>Fin</Th>
            </Tr>
          </Thead>
          <Tbody>
            {carLocations.map((location) => {
              const { firstName, lastName } = location.client;
              const { marque } = location.vehicle;
              const { startDate, endDate } = location;

              return (
                <Box
                  as={Tr}
                  key={location._id}
                  _hover={{ bg: "rgb(251, 251, 251)", cursor: "pointer" }}
                  onClick={() => {
                    setCarLocation(location);
                    onOpen();
                  }}
                >
                  <Td>{`${firstName} ${lastName}`}</Td>
                  <Td>{marque}</Td>
                  <Td>{moment(startDate).format("DD MMMM YYYY")}</Td>
                  <Td>{moment(endDate).format("DD MMMM YYYY")}</Td>
                </Box>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ReservationList;
