import api from "./axios";

export const fetchInformations = async () => {
  const response = await api.get("/informations");
  return response.data;
};

export const updateInformations = async (id, updatedInformation) => {
  const response = await api.put("/informations/" + id, updatedInformation);
  return response.data;
};
