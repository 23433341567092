import axiosInstance from "./axios";
import moment from "moment";

// GET_Paiement-entre-2-date
export const getPaiement = async (start, end, typePaie) => {
  return await axiosInstance
    .get("/paiement/getPaiement", {
      params: {
        start: moment(start).toISOString(),
        end: moment(end).toISOString(),
        typePaie: typePaie,
      },
    })
    .then((listePaiement) => {
      return listePaiement.data;
    })
    .catch((error) => {
      console.log(error);
    });
};
///Get all type de paiement
export const getAllTypePaie = async () => {
  return await axiosInstance
    .get("/paie/getAllTypePaie")
    .then((listeTypePaie) => {
      return listeTypePaie.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

//Payer réservation
export const payer = async (
  idReservation,
  idTypePaie,
  montant,
  remise,
  date
) => {
  await axiosInstance
    .post("/paiement/Payer", {
      idReservation,
      idTypePaie,
      montant,
      remise,
      date,
    })
    .then((res) => {
      alert(res.data.message);
    })
    .catch((err) => {
      console.log(err);
    });
};
export const getPaiementId = async (id) => {
  return await axiosInstance
    .get(`/paiement/getPaiement/` + id, {})
    .then((reservation) => {
      return reservation.data;
    })
    .catch((err) => {
      console.log(err);
    });
};
