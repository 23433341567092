import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { FormControl, FormLabel, Input, Button, Textarea, Select } from "@chakra-ui/react";
import * as breakfastService from "../../api/Breakfast";

export default function AddSectionModal({ optionToUpdate, show, onHide, breakfastId, updateBreakfasts, isExtra }) {
  const [title, setTitle] = useState("");
  const [values, setValues] = useState("");
  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState(0);
  const [isMultiple, setIsMultiple] = useState(false);

  const resetAllFields = () => {
    setTitle("");
    setValues("");
    setPrice(0);
    setIsMultiple(false);
  };

  useEffect(() => {
    if (optionToUpdate) {
      setTitle(optionToUpdate.title);
      setValues(optionToUpdate.values.join(", "));
      setIsMultiple(optionToUpdate.multiple);
      if (isExtra) {
        setPrice(optionToUpdate.price);
      }
    }

    return () => {
      resetAllFields();
    };
  }, [optionToUpdate, isExtra]);

  const handleSubmit = async ({ title, values }) => {
    setLoading(true);
    try {
      let updatedBreakfast;
      if (optionToUpdate !== null) {
        // updating
        const data = {
          breakfastId,
          optionId: optionToUpdate._id,
          title,
          values,
        };
        if (isExtra) {
          data.price = parseInt(price);
        }
        data.multiple = isMultiple;

        updatedBreakfast = await breakfastService.updateSection(data);
      } else {
        // adding
        const data = {
          breakfastId,
          title,
          values,
        };
        data.multiple = isMultiple;

        if (isExtra) {
          data.price = parseInt(price);
        }

        updatedBreakfast = await breakfastService.createSection(data);
      }

      if (updatedBreakfast) {
        // the last params is to check if it is an extra or not
        updateBreakfasts(breakfastId, updatedBreakfast, isExtra);
      }
      resetAllFields();
      onHide();
    } catch {
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal show={show} onHide={onHide} centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{optionToUpdate ? "Modifier une section" : "Ajouter une section"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              const formattedValues = values.split(",").map((e) => e.trim());
              if (isExtra && formattedValues.length > 0) {
                handleSubmit({
                  title,
                  values: formattedValues,
                });
                return;
              }
              if (title.length > 1 && formattedValues.length > 0) {
                handleSubmit({
                  title,
                  values: formattedValues,
                });
              }
            }}
          >
            <FormControl>
              {!isExtra ? <FormLabel>Titre</FormLabel> : <FormLabel>Section (optionnel)</FormLabel>}
              <Input type="text" required={!isExtra} value={title} placeholder="Ex : Jus de fruit" onChange={(e) => setTitle(e.target.value)} />
            </FormControl>
            {isExtra && (
              <FormControl className="mt-4">
                <FormLabel>Prix</FormLabel>
                <Input type="number" required value={price} onChange={(e) => setPrice(e.target.value)} />
              </FormControl>
            )}
            <FormControl className="mt-4">
              <FormLabel>Sélection</FormLabel>
              <Select
                value={isMultiple ? "multiple" : "single"}
                onChange={(e) => {
                  setIsMultiple(e.target.value === "multiple");
                }}
              >
                <option value="single">Une seule</option>
                <option value="multiple">Multiple</option>
              </Select>
            </FormControl>
            <FormControl className="mt-4">
              <FormLabel>Choix (séparer par des virgules)</FormLabel>
              <Textarea type="text" required resize={"vertical"} value={values} rows={5} placeholder="Ex : Poire, Mangue, Pomme" onChange={(e) => setValues(e.target.value)} />
            </FormControl>
            <div className="mt-5 w-100 d-flex justify-content-end">
              <Button type="button" marginX={3} colorScheme="blue" variant="ghost" onClick={onHide}>
                Annuler
              </Button>
              <Button isLoading={loading} type="submit" colorScheme="blue" variant="solid">
                {optionToUpdate !== null ? "Modifier" : "Ajouter"}
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
