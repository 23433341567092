import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "@chakra-ui/react";
import * as breakfastService from "../../api/Breakfast";

function DeleteModal({
  optionToDeleteId,
  show,
  onHide,
  isExtra, 
  breakfastId,
  breakfastToDelete,
  updateBreakfasts,
  removeMenu
}) {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (breakfastToDelete) {
        // Deleting menu
        await breakfastService.deleteMenu({
          breakfastId: breakfastToDelete._id,
        });
        removeMenu(breakfastToDelete._id)
      } else {
        // Deleting section
        const updatedBreakfast = await breakfastService.deleteSection({
          breakfastId,
          optionId: optionToDeleteId,
        });
        updateBreakfasts(breakfastId, updatedBreakfast, isExtra);
      }

      onHide();
    } catch {
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal show={show} onHide={onHide} centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Suppression</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <p>
              Voulez vous vraiment confirmer la suppression{" "}
              {breakfastToDelete ? "du menu : " + breakfastToDelete.title : ""}{" "}
              ?
            </p>
            <div className="mt-5 w-100 d-flex justify-content-end">
              <Button
                type="button"
                marginX={3}
                colorScheme="gray"
                variant="ghost"
                onClick={onHide}
              >
                Annuler
              </Button>
              <Button
                isLoading={loading}
                type="submit"
                colorScheme="red"
                variant="solid"
              >
                Supprimer
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default DeleteModal;
