import { Flex, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, Box, SimpleGrid } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
// import { Modal } from "react-bootstrap"
import { Link } from "react-router-dom";
import './index.css';
// Custom components
import Card from "components/card/Card";
import { getAllTypePaie } from "../../../api/Paiement";
import { formateNom } from "../../../fonction/formateNom"
import { getPaiementLocation } from "api/paiementLocation";

export default function Settings() {
  const [paiement, setListPaiement] = useState([]);
  const [typePaie, setTypePaie] = useState([]);
  var date = new Date();
  date.setDate(1);
  const dateDebut = date.toLocaleDateString('en-CA');
  date = new Date();
  date.setDate(date.getDate() + 1);
  const dateFin = date.toLocaleDateString('en-CA');
  const [forms, setForms] = useState({
    dateDebut: dateDebut,
    dateFin: dateFin,
  });
  const changeHandler = e => {
    setForms({ ...forms, [e.target.name]: e.target.value });
  };
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  var total = 0;
  const [somme, setSomme] = useState(0)
  useEffect(() => {
    if (typePaie.length === 0) {
      getPaiementLocation(forms.dateDebut, forms.dateFin).then((listePaiement) => {
        setListPaiement(listePaiement);
        console.log("liste paiement",listePaiement)
        listePaiement.map((unPaiement) => {
          total += unPaiement.montantPayer;
          setSomme(total)
        })
      });
      getAllTypePaie().then((hotelType) => {
        setTypePaie(hotelType);
      });
    }
  }, [typePaie.length, forms.dateDebut, forms.dateFin]);
  
  function rechercher() {
    const typePaie = (document.getElementById("typePaie")).value;
    if (typePaie === '') {
      getPaiementLocation(forms.dateDebut, forms.dateFin).then((listePaiement) => {
        setListPaiement(listePaiement);
      });
    } else {
      getPaiementLocation(forms.dateDebut, forms.dateFin, typePaie).then((listePaiement) => {
        setListPaiement(listePaiement);
      });
    }
  }
  
  const [selectedValue, setSelectedValue] = useState('')
  useEffect(() => {
    liste()
  }, [selectedValue])

  const liste = () => {
    const choixListe = document.getElementById("choixListe")?.value
    setSelectedValue(choixListe)
    if (selectedValue === "chambre") {
      window.location.href = "/#/admin/paiement"
    }
    else if (selectedValue === "location") {
      window.location.href = "/#/admin/paiementLocation"
    }
  }
  return (

    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
    <div className="col-12 d-flex align-items-baseline">
      <div className="submit-area mb-2 col-6">
        <select name="choixListe" id="choixListe" onClick={() => liste()} className="text-light bg-danger selectChoix my-3 p-2">
          <option value="" hidden>Chambre / Voiture</option>
          <option value="chambre" className='text-light'>Liste de paiement de chambre</option>
          <option value="location" className='text-light'>Liste de paiement de voiture</option>
        </select>
      </div>
    </div>
      <SimpleGrid
        columns={{ base: 1, md: 1, xl: 1 }}
        spacing={{ base: "20px", xl: "20px" }}>
        <form className="contact-validation-active " >
          <div className="row d-flex justify-content-between">
            <div className="col-12 col-md-8 col-lg-8 d-flex justify-content-around align-items-baseline">
              <div className="col-2">
                <div className="form-field">
                  <label>
                    Date de debut:
                    <input id="dateDebut" className="form-control" value={forms.dateDebut} type="date" name="dateDebut" onBlur={(e) => changeHandler(e)} onChange={(e) => changeHandler(e)} />
                  </label>
                </div>
              </div>
              <div className="col-2">
                <div className="form-field">
                  <label>
                    Date de fin:
                    <input id="dateFin" className="form-control" value={forms.dateFin} type="date" name="dateFin" onBlur={(e) => changeHandler(e)} onChange={(e) => changeHandler(e)} />
                  </label>
                </div>
              </div>
              <div className="col-2">
                <div className="form-field">
                  <label>
                    Paiement par:
                    <select name="typePaie" id="typePaie" className="form-control">
                      {typePaie.map((uneTypePaie) => {
                        return <option default={uneTypePaie?._id} value={uneTypePaie?._id} key={uneTypePaie?._id}>{uneTypePaie?.type}</option>
                      })};
                    </select>
                  </label>
                </div>
              </div>
              <div className="col-2">
                <div className="submit-area">
                  <Link className="btn btn-danger" onClick={(e) => {
                    rechercher(e)
                  }} to="/admin/paiement" >Rechercher</Link>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3 col-lg-3 border d-flex flex-column align-items-center ">
              <Text color="red" fontWeight='700' lineHeight='100%' className="sommePaie mt-3"> Total : </Text>
              <Text fontSize='22px' fontWeight='700' className="mb-1 text-muted" lineHeight='100%'>{somme.toLocaleString()} Ar </Text>
            </div>
          </div>
        </form >
        <Card
          direction='column'
          w='100%'
          px='0px'
          className="tablePaiement">
          <Flex px='25px' justify='space-between' mb='20px' align='center'>
            <Text
              color={textColor}
              fontSize='22px'
              fontWeight='700'
              lineHeight='100%'>
              Liste des paiements de location de voiture
            </Text>
          </Flex>
          <Table variant='simple' color='gray.500' mb='24px'>
            <Thead>
              <Tr>
                <Th pe='100px' borderColor={borderColor}>Date de paiement
                  <Flex justify='space-between' align='center' fontSize={{ sm: "10px", lg: "12px" }} color='gray.400'> </Flex>
                </Th>
                <Th pe='200px' borderColor={borderColor}>Client
                  <Flex justify='space-between' align='center' fontSize={{ sm: "10px", lg: "12px" }} color='gray.400'> </Flex>
                </Th>
                <Th pe='125px' borderColor={borderColor}>Check In
                  <Flex justify='space-between' align='center' fontSize={{ sm: "10px", lg: "10px" }} color='gray.400'> </Flex>
                </Th>
                <Th pe='125px' borderColor={borderColor}>Check Out
                  <Flex justify='space-between' align='center' fontSize={{ sm: "10px", lg: "12px" }} color='gray.400'> </Flex>
                </Th>
                <Th pe='50px' borderColor={borderColor}>Type de paiement
                  <Flex justify='space-between' align='center' fontSize={{ sm: "10px", lg: "12px" }} color='gray.400'> </Flex>
                </Th>
                <Th pe='50px' borderColor={borderColor}>Montant
                  <Flex justify='space-between' align='center' fontSize={{ sm: "10px", lg: "12px" }} color='gray.400'> </Flex>
                </Th>
                <Th pe='10px' borderColor={borderColor}>Remise
                  <Flex justify='space-between' align='center' fontSize={{ sm: "10px", lg: "12px" }} color='gray.400'> </Flex>
                </Th>
                <Th pe='50px' borderColor={borderColor}>Montant Payer
                  <Flex justify='space-between' align='center' fontSize={{ sm: "10px", lg: "12px" }} color='gray.400'> </Flex>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {paiement.length > 0 && paiement.map((unePaiement) => {
                const [annee, mois, jour] = unePaiement?.datePaie.split('T')[0].split('-');
                const datePaie = [jour, mois, annee].join('-');
                const [annee1, mois1, jour1] = unePaiement?.idLocation?.start.split('T')[0].split('-');
                const dateStart = [jour1, mois1, annee1].join('-');
                const [annee2, mois2, jour2] = unePaiement?.idLocation?.end.split('T')[0].split('-');
                const dateEnd = [jour2, mois2, annee2].join('-');
                return (
                   <Tr key={unePaiement?._id}>
                  {/* <Tr> */}
                    <Td fontSize={{ sm: "14px" }} maxH='30px !important' py='8px' minW={{ sm: "150px", md: "200px", lg: "auto" }} borderColor='transparent'>
                      <Text color={textColor} fontSize='sm' fontWeight='700'>  {datePaie} </Text>
                    </Td>
                    <Td fontSize={{ sm: "14px" }} maxH='30px !important' py='8px' minW={{ sm: "150px", md: "200px", lg: "auto" }} borderColor='transparent'>
                      <Text color={textColor} fontSize='sm' fontWeight='700'>{unePaiement?.idLocation?.client?.prenom + ' ' + formateNom(unePaiement?.idLocation?.client?.nom)}</Text>
                    </Td>
                    <Td fontSize={{ sm: "14px" }} maxH='30px !important' py='8px' minW={{ sm: "150px", md: "200px", lg: "auto" }} borderColor='transparent'>
                      <Text color={textColor} fontSize='sm' fontWeight='700'> {dateStart} </Text>
                    </Td>
                    <Td fontSize={{ sm: "14px" }} maxH='30px !important' py='8px' minW={{ sm: "150px", md: "200px", lg: "auto" }} borderColor='transparent'>
                      <Text color={textColor} fontSize='sm' fontWeight='700'> {dateEnd} </Text>
                    </Td>
                    <Td fontSize={{ sm: "14px" }} maxH='30px !important' py='8px' minW={{ sm: "150px", md: "200px", lg: "auto" }} borderColor='transparent'>
                      <Text color={textColor} fontSize='sm' fontWeight='700'> {unePaiement?.idTypePaie?.type} </Text>
                    </Td>
                    <Td fontSize={{ sm: "14px" }} maxH='30px !important' py='8px' minW={{ sm: "150px", md: "200px", lg: "auto" }} borderColor='transparent'>
                      <Text color={textColor} fontSize='sm' fontWeight='700'> {unePaiement?.montant.toLocaleString()}  Ar</Text>
                    </Td>
                    <Td fontSize={{ sm: "14px" }} maxH='30px !important' py='8px' minW={{ sm: "150px", md: "200px", lg: "auto" }} borderColor='transparent'>
                      <Text color={textColor} fontSize='sm' fontWeight='700'> {unePaiement?.remise ? unePaiement?.remise : 0}  %</Text>
                    </Td>
                    <Td fontSize={{ sm: "14px" }} maxH='30px !important' py='8px' minW={{ sm: "150px", md: "200px", lg: "auto" }} borderColor='transparent'>
                      <Text color={textColor} fontSize='sm' fontWeight='700'> {unePaiement?.montantPayer.toLocaleString()}  Ar</Text>
                    </Td>
                  </Tr>
                  );
               })} 
            </Tbody>
          </Table>
        </Card>
      </SimpleGrid>
    </Box>
  );
}
