import axiosInstance from "./axios";

// GET_Paiement-entre-2-date
// export const verifier = async (email, mdp) => {
//   return await axiosInstance
//     .get("/user/verifier", {
//       params: {
//         email: email,
//         mdp: mdp,
//       },
//     })
//     .then((user) => {
//       return user.data;
//     })
//     .catch((error) => {
//       console.log(error);
//     });
// };

export const verifier = async (email, password) => {
  return axiosInstance
    .post("/user/verifier", {
      email: email,
      mdp: password,
    })
    .then((user) => user.data)
    .catch((error) => {
      console.log("Error while logging user : ", error);
    });
};

export async function login(email, password) {
  const response = await axiosInstance.post("/user/verifier", {
    email,
    mdp: password,
  });
  return response.data;
}
