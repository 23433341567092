import {Box, SimpleGrid, Flex, Table, Tbody, Td, Text, Th, Thead,  Tr,  useColorModeValue} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {formateNom} from "../../../fonction/formateNom"
import {Link} from "react-router-dom";
import './index.css'
// Custom components
import Card from "components/card/Card";
import {getLocationVoiture} from "../../../api/location"

export default function UserReports() {
  const [nonPayer, setNonPayer] = useState([])


  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  useEffect(() => {
        getLocationVoiture().then((listeNonPayer) => {
          const newListe = listeNonPayer.filter(nonPayer=>nonPayer.etat === 0 && nonPayer.etatPaie === 0)
          setNonPayer(newListe)
      });
  },[]);
   
  const [selectedValue, setSelectedValue] = useState('')
  useEffect(()=>{
    liste()
  }, [selectedValue])
  const liste = () => {
    const choixListe = document.getElementById("choixListe")?.value
    setSelectedValue(choixListe)
    if(selectedValue === "chambre"){
      window.location.href = "/#/admin/nonPayer"
    }
    else if(selectedValue === "location"){
      window.location.href = "/#/admin/locationNonPayer"
    }
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
     
      <select name="choixListe" id="choixListe" onClick={()=>liste()} className="text-light bg-danger selectChoix my-3 p-2">
        <option value="" hidden>Chambre / Location</option>
        <option value="chambre" className='text-light'>Liste des chambres non payée</option>
        <option value="location" className='text-light'>Liste des locations non payée</option>
      </select>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mb='20px'>
      <Card
    direction='column'
    w='100%'
    px='0px'
    className="tableNonPayer">
    <Flex px='25px' justify='space-between' mb='10px' align='center'>
      <Text color={textColor} fontSize='22px' fontWeight='700' lineHeight='100%'>
        Liste des locations non payées
      </Text>
    </Flex>
    <Table variant='striped' color='gray.500' mb='24px'>
      <Thead>
        <Tr>
          <Th pe='250px' borderColor={borderColor}>Marque du véhicule
            <Flex justify='space-between' align='center' fontSize={{ sm: "10px", lg: "12px" }} color='gray.400'>  </Flex>
          </Th>
          <Th pe='100px' borderColor={borderColor}>Nom
            <Flex justify='space-between' align='center' fontSize={{ sm: "10px", lg: "12px" }} color='gray.400'>  </Flex>
          </Th>
          <Th pe='10px' borderColor={borderColor}>Prénom
            <Flex justify='space-between' align='center' fontSize={{ sm: "10px", lg: "12px" }} color='gray.400'> </Flex>
          </Th>
          <Th pe='100px' borderColor={borderColor}>Email
            <Flex justify='space-between' align='center' fontSize={{ sm: "10px", lg: "12px" }} color='gray.400'> </Flex>
          </Th>
          <Th pe='100px' borderColor={borderColor}>Numéro
            <Flex justify='space-between' align='center' fontSize={{ sm: "10px", lg: "12px" }} color='gray.400'> </Flex>
          </Th>
          <Th pe='100px' borderColor={borderColor}>Start
            <Flex justify='space-between' align='center' fontSize={{ sm: "10px", lg: "12px" }} color='gray.400'> </Flex>
          </Th>
          <Th pe='100px' borderColor={borderColor}>End
            <Flex justify='space-between' align='center' fontSize={{ sm: "10px", lg: "12px" }} color='gray.400'> </Flex>
          </Th>
          <Th pe='100px' borderColor={borderColor}>Déstination
            <Flex justify='space-between' align='center' fontSize={{ sm: "10px", lg: "12px" }} color='gray.400'> </Flex>
          </Th>
          <Th pe='100px' borderColor={borderColor}>Trajet
            <Flex justify='space-between' align='center' fontSize={{ sm: "10px", lg: "12px" }} color='gray.400'> </Flex>
          </Th>

        </Tr>
      </Thead>
      <Tbody>
        {nonPayer.map((clientNonPayer) => {
          const [annee, mois, jour] = clientNonPayer?.start.split('T')[0].split('-');
          const dateIn = [jour, mois ,annee].join('-');
          const [annee2, mois2, jour2] = clientNonPayer?.end.split('T')[0].split('-');
          const dateOut = [jour2, mois2 ,annee2].join('-');
          return (
            <Tr key={clientNonPayer?._id}>
            <Td fontSize={{ sm: "14px" }}  maxH='30px !important' py='8px'  minW={{ sm: "150px", md: "200px", lg: "auto" }} borderColor='transparent'>
              <Text color={textColor} fontSize='sm' fontWeight='700'>
                {clientNonPayer?.marque}
              </Text>
            </Td>
              <Td fontSize={{ sm: "14px" }} maxH='30px !important' py='8px' minW={{ sm: "150px", md: "200px", lg: "auto" }} borderColor='transparent'>
                <Text color={textColor} fontSize='sm' fontWeight='700'>
                  {formateNom(clientNonPayer?.client?.nom)}
                </Text>
              </Td>
              <Td fontSize={{ sm: "14px" }}  maxH='30px !important' py='8px'  minW={{ sm: "150px", md: "200px", lg: "auto" }} borderColor='transparent'>
                <Text color={textColor} fontSize='sm' fontWeight='700'>
                  {clientNonPayer?.client?.prenom}
                </Text>
              </Td>
              <Td fontSize={{ sm: "14px" }} maxH='30px !important' py='8px' minW={{ sm: "150px", md: "200px", lg: "auto" }} borderColor='transparent'>
                <Text color={textColor} fontSize='sm' fontWeight='700'>
                  {clientNonPayer?.client?.email}
                </Text>
              </Td>
              <Td fontSize={{ sm: "14px" }}  maxH='30px !important' py='8px' minW={{ sm: "150px", md: "200px", lg: "auto" }} borderColor='transparent'>
                <Text color={textColor} fontSize='sm' fontWeight='700'>
                  {clientNonPayer?.client?.numero}
                </Text>
              </Td>
              <Td fontSize={{ sm: "14px" }}  maxH='30px !important' py='8px'  minW={{ sm: "150px", md: "200px", lg: "auto" }} borderColor='transparent'>
                <Text color={textColor} fontSize='sm' fontWeight='700'>
                  {dateIn}
                </Text>
              </Td>
              <Td fontSize={{ sm: "14px" }} maxH='30px !important' py='8px' minW={{ sm: "150px", md: "200px", lg: "auto" }} borderColor='transparent'>
                <Text color={textColor} fontSize='sm' fontWeight='700'>
                  {dateOut}
                </Text>
              </Td>
              <Td fontSize={{ sm: "14px" }} maxH='30px !important' py='8px'  minW={{ sm: "150px", md: "200px", lg: "auto" }} borderColor='transparent'>
                <Text color={textColor} fontSize='sm' fontWeight='700'>
                  {clientNonPayer?.province}
                </Text>
              </Td>
              <Td fontSize={{ sm: "14px" }} maxH='30px !important' py='8px'  minW={{ sm: "150px", md: "200px", lg: "auto" }} borderColor='transparent'>
                <Text color={textColor} fontSize='sm' fontWeight='700'>
                  {clientNonPayer?.trajet}
                </Text>
              </Td>
                <Td fontSize={{ sm: "10px" }} maxH='20px !important' py='4px' minW={{ sm: "100px", md: "100px", lg: "auto" }} borderColor='transparent'>
                  <Link to={`/admin/payerLocation/${clientNonPayer?._id}`} className="theme-btn bValider">
                    <Text color={textColor} fontSize='sm' fontWeight='700' className="bg-danger rounded px-3 py-2 text-light">  Payer </Text>
                  </Link>
                </Td>
            </Tr>
           );
        })}
      </Tbody>
    </Table>
  </Card>
      </SimpleGrid>
    </Box>
  );
}
