import React, { useEffect, useState } from "react";
import { Flex, Input, Text, Button, IconButton } from "@chakra-ui/react";
import { FaPen } from "react-icons/fa";
import { updateEditableText } from "../../api/EditableText";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EditableText = (props) => {
  const { placeholder, textKey, initialValue } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const showToast = (type, message) => {
    toast[type](message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
    });
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      await updateEditableText(textKey, value);
      showToast("success", "Texte mis à jour avec succès !");
      setIsEditing(false);
    } catch (error) {
      showToast("error", "Échec de la mise à jour du texte.");
      setValue(initialValue ?? "");
    } finally {
      setIsLoading(false);
    }
  };

  const EditButton = () => {
    return (
      <IconButton
        colorScheme="blue"
        icon={<FaPen />}
        ml={3}
        variant="ghost"
        onClick={() => {
          setIsEditing((v) => !v);
        }}
      />
    );
  };

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (value.length > 0 && textKey) {
            handleSubmit();
          }
        }}
        className="my-5"
      >
        <Text mb="5px" fontSize={"1.1em"} fontWeight={"bold"}>
          {placeholder}
          {value === undefined && <EditButton />}
        </Text>
        <Flex alignItems={"center"}>
          {isEditing ? <Input required value={value || ""} onChange={(e) => setValue(e.target.value)} htmlSize={5} placeholder="Écrivez..." /> : <Text>{value}</Text>}
          {!isEditing && value !== undefined && <EditButton />}
        </Flex>
        {isEditing && (
          <Flex mt={4}>
            <Button
              type="button"
              onClick={() => {
                setIsEditing(false);
                setValue(initialValue);
              }}
              disabled={isLoading}
            >
              Annuler
            </Button>
            <Button type="submit" ml={4} colorScheme="blue" isLoading={isLoading}>
              Sauvegarder
            </Button>
          </Flex>
        )}
      </form>
    </>
  );
};

export default EditableText;
