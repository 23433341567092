import {
  Flex, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue,
} from "@chakra-ui/react";
import {BsTrash, BsPencil} from 'react-icons/bs'
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// Custom components
import Card from "components/card/Card";
import { getVoiture, deleteVoiture } from "api/Voiture";
import { Modal } from "react-bootstrap";
import './index.css'

// Assets
export default function ColumnsTable(props) {
  const [voiture, setVoiture] = useState([]);


  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  useEffect(() => {
    getVoiture().then((listeVoiture) => {
      setVoiture(listeVoiture);
    });
  }, []);

  function confirmation(id) {
    if (window.confirm("Voulez-vous vraiment supprimer cette voiture de la liste?")) {
      deleteVoiture(id);
      const newVoitures = voiture.filter((el) => el._id !== id);
      setVoiture(newVoitures);
    } else {
    }
  }

  const [selectedValue, setSelectedValue] = useState('')
  useEffect(() => {
    liste()
  }, [selectedValue])
  const liste = () => {
    const choixListe = document.getElementById("choixListe")?.value
    setSelectedValue(choixListe)
    if (selectedValue === "chambre") {
      window.location.href = "/#/admin/chambre"
    }
    else if (selectedValue === "location") {
      window.location.href = "/#/admin/location"
    }
  }
  const [newCar, setNewCar] = useState(false)
  const Enregistrer = (e) =>{
    const formVoiture = document.getElementById('formVoiture')
    formVoiture.submit()
  }
  return (
    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <div className="col-12 d-flex align-items-baseline">
        <div className="submit-area mb-2 col-6">
          <select name="choixListe" id="choixListe" onClick={() => liste()} 
            className="selectChoix my-3 p-2"
            style={{ border: "1px solid darkGray" }}
            >
            <option value="" hidden>Chambre / Location</option>
            <option value="chambre" className=''>Liste des chambres</option>
            <option value="location" className=''>Liste des voitures</option>
          </select>
        </div>
      </div>
      <Flex px='25px' justify='space-between' mb='10px' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%'>
          Liste des voitures
            </Text>
          {/* <div className="submit-area mb-2"> */}
              <button onClick={() => {
                setNewCar(true)
                }} 
                className="btn text-light rounded-0 border-0 col-3"
                style={{ background: '#2ecc71' }}>
                Ajouter une nouvelle voiture
              </button>
          {/* </div> */}
      </Flex>

    <Modal show={newCar} onHide={()=>setNewCar(false)} scrollable={true} backdrop='static' keyboard={false} centered className="border-0" size="md">
      <Modal.Header closeButton onClick={()=>setNewCar(false)}>
          <div className="w-100 d-flex justify-content-center">
            <p className="titreModal">Ajout d'une nouvelle voiture</p>
          </div>
      </Modal.Header>
      <Modal.Body>
          {/* <form id="formVoiture" action="http://localhost:586/voiture/creerVoiture" method="post" encType='multipart/form-data'> */}
            <form id="formVoiture" action="https://root.valihahotel.com/voiture/creerVoiture" method="post" encType='multipart/form-data'>
            <div className="form-group row">
              <div className="form-group col-12 col-md-12 col-lg-12 d-flex flex-wrap justify-content-around">
                <div className="col-12 col-md-12 col-lg-12 d-flex justify-content-evenly my-2">
                  <label htmlFor="" className="col-3 col-form-label">Marque : </label>
                  <div className="col-8 col-md-8 col-lg-8">
                    <input id="titre" className="form-control py-2" type="text" name="marque" placeholder="Marque de la voiture" />
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-12 d-flex justify-content-evenly my-2">
                  <label htmlFor="" className="col-3 col-form-label">Image : </label>
                  <div className="col-8 col-md-8 col-lg-8">
                    <input id="image" className="form-control py-2" type="file" name="voitureImage" placeholder="fichier choisi" />
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-12 d-flex justify-content-evenly my-2">
                  <label htmlFor="" className="col-3 col-form-label">Kilométrage : </label>
                  <div className="col-8 col-md-8 col-lg-8">
                    <input id="titre" className="form-control py-2" type="text" name="kilometrage" placeholder="Litres aux 100km" />
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-12 d-flex justify-content-evenly my-2">
                  <label htmlFor="" className="col-4 col-form-label">Modèle / année : </label>
                  <div className="col-7 col-md-7 col-lg-7 ">
                    <input id="annee" className="form-control" type="text" name="annee" placeholder="Modèle ou année" />
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-12 d-flex justify-content-evenly my-2">
                  <label htmlFor="" className="col-6 col-form-label">Prix de location par jour: </label>
                  <div className="col-5 col-md-5 col-lg-5">
                    <input id="prix" className="form-control py-2" type="number" min="0" name="prix" placeholder="Prix" />
                  </div>
                </div>
                <div className="col-4 col-md-4 col-lg-4 d-flex justify-content-evenly my-2">
                  <label htmlFor="" className="col-5 col-form-label">Roues : </label>
                  <div className="col-4 col-md-4 col-lg-4 ">
                    <input id="entrainement" className="form-control" type="number" min="0" name="entrainement" />
                  </div>
                </div>
                <div className="col-4 col-md-4 col-lg-4 d-flex justify-content-evenly my-2">
                  <label htmlFor="" className="col-5 col-form-label">Portes : </label>
                  <div className="col-4 col-md-4 col-lg-4 ">
                    <input id="portes" className="form-control" type="number" min="0" name="portes" />
                  </div>
                </div>
                <div className="col-4 col-md-4 col-lg-4 d-flex justify-content-evenly my-2">
                  <label htmlFor="" className="col-5 col-form-label">Places : </label>
                  <div className="col-4 col-md-4 col-lg-4 ">
                    <input id="places" className="form-control" type="number" min="0" name="places" />
                  </div>
                </div>
              </div>
            </div>
            <div className="submit-area mt-4 col-12 col-md-12 col-lg-12 d-flex justify-content-end">
              <Link 
                className="btn" 
                onClick={(e) => {
                  Enregistrer(e)
                  setNewCar(false)
                }}
                to="/admin/voiture"
                style={{ background :'#2ecc71', borderRadius: '3px', color: 'white' }}
                >Ajouter</Link>
            </div>
          </form>
      </Modal.Body>
    </Modal>


      <Table variant='striped' color='gray.500' mb='24px'>
        <Thead>
          <Tr>
            <Th pe='10px' borderColor={borderColor}>Marque
              <Flex justify='space-between' align='center' fontSize={{ sm: "10px", lg: "12px" }} color='gray.400'> </Flex>
            </Th>
            <Th pe='10px' borderColor={borderColor}>Roues
              <Flex justify='space-between' align='center' fontSize={{ sm: "10px", lg: "12px" }} color='gray.400'> </Flex>
            </Th>
            <Th pe='10px' borderColor={borderColor}>Kilométrage
              <Flex justify='space-between' align='center' fontSize={{ sm: "10px", lg: "12px" }} color='gray.400'> </Flex>
            </Th>
            <Th pe='10px' borderColor={borderColor}>Portes
              <Flex justify='space-between' align='center' fontSize={{ sm: "10px", lg: "12px" }} color='gray.400'> </Flex>
            </Th>
            <Th pe='10px' borderColor={borderColor}>Places
              <Flex justify='space-between' align='center' fontSize={{ sm: "10px", lg: "12px" }} color='gray.400'> </Flex>
            </Th>
            <Th pe='10px' borderColor={borderColor}>Modèle / année
              <Flex justify='space-between' align='center' fontSize={{ sm: "10px", lg: "12px" }} color='gray.400'> </Flex>
            </Th>
            <Th pe='10px' borderColor={borderColor}>Prix par jour
              <Flex justify='space-between' align='center' fontSize={{ sm: "10px", lg: "12px" }} color='gray.400'> </Flex>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {voiture.map((uneVoiture) => {
            return (
              <Tr key={uneVoiture._id}>
                <Td fontSize={{ sm: "14px" }} maxH='30px !important' py='8px' minW={{ sm: "150px", md: "200px", lg: "auto" }} borderColor='transparent'>
                  <Text color={textColor} fontSize='sm' fontWeight='700'> {uneVoiture?.marque} </Text>
                </Td>
                <Td fontSize={{ sm: "14px" }} maxH='30px !important' py='8px' minW={{ sm: "150px", md: "200px", lg: "auto" }} borderColor='transparent'>
                  <Text color={textColor} fontSize='sm' fontWeight='700'> {uneVoiture?.entrainement} </Text>
                </Td>
                <Td fontSize={{ sm: "14px" }} maxH='30px !important' py='8px' minW={{ sm: "150px", md: "200px", lg: "auto" }} borderColor='transparent'>
                  <Text color={textColor} fontSize='sm' fontWeight='700'> {uneVoiture?.kilometrage} litres aux 100km</Text>
                </Td>
                <Td fontSize={{ sm: "14px" }} maxH='30px !important' py='8px' minW={{ sm: "150px", md: "200px", lg: "auto" }} borderColor='transparent'>
                  <Text color={textColor} fontSize='sm' fontWeight='700'> {uneVoiture?.portes} </Text>
                </Td>
                <Td fontSize={{ sm: "14px" }} maxH='30px !important' py='8px' minW={{ sm: "150px", md: "200px", lg: "auto" }} borderColor='transparent'>
                  <Text color={textColor} fontSize='sm' fontWeight='700'> {uneVoiture?.places} </Text>
                </Td>
                <Td fontSize={{ sm: "14px" }} maxH='30px !important' py='8px' minW={{ sm: "150px", md: "200px", lg: "auto" }} borderColor='transparent'>
                  <Text color={textColor} fontSize='sm' fontWeight='700'> {uneVoiture?.annee} </Text>
                </Td>
                <Td fontSize={{ sm: "14px" }} maxH='30px !important' py='8px' minW={{ sm: "150px", md: "200px", lg: "auto" }} borderColor='transparent'>
                  <Text color={textColor} fontSize='sm' fontWeight='700'> {uneVoiture?.prix.toLocaleString()} Ar </Text>
                </Td>

                <Td fontSize={{ sm: "10px" }} maxH='20px !important' py='4px' minW={{ sm: "100px", md: "100px", lg: "auto" }} borderColor='transparent'>
                  <Link onClick={() => {
                    const id = uneVoiture._id;
                    confirmation(id);
                    console.log("id", id);
                  }} to="/admin/voiture" className="theme-btn bValider"> <BsTrash className="font"/>
                  </Link>
                </Td>
                <Td fontSize={{ sm: "10px" }} maxH='20px !important' py='4px' minW={{ sm: "100px", md: "100px", lg: "auto" }} borderColor='transparent'>
                  <Link to={`/admin/modifier/${uneVoiture._id}`} params={{ testvalue: "hello" }}><BsPencil className="font"/></Link>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Card>
  );
}
