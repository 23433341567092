import { useEffect, useState } from "react";
import * as breakfastService from "../../../api/Breakfast";
import { Button } from "@chakra-ui/react";
import AddMenuModal from "components/breakfast/AddMenuModal";
import AddSectionModal from "components/breakfast/AddSectionModal";
import DeleteModal from "components/breakfast/DeleteModal";
import ExportButton from "components/breakfast/ExportButton";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Menu from "./Menu";
import * as editableTextService from "../../../api/EditableText";
import EditableText from "components/editableText";

function Breakfast() {
  const [breakfasts, setBreakfasts] = useState([]);
  const [extra, setExtra] = useState([]);
  const [editableTexts, setEditableTexts] = useState([]);

  const getEditableText = (key) => {
    return editableTexts.find((e) => e.key === key);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const breakfastsData = await breakfastService.getAllBreakfasts();
        const editableTextsData = await editableTextService.getAllEditableTexts();
        setBreakfasts(breakfastsData.breakfasts);
        setExtra(breakfastsData.extra);
        setEditableTexts(editableTextsData);
      } catch {
        setBreakfasts([]);
        setExtra([]);
      }
    }

    fetchData();
  }, []);

  const [showAddMenu, setShowAddMenu] = useState(false);
  const [showAddSection, setShowAddSection] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const addMenu = (menu) => {
    setBreakfasts((prev) => [...prev, menu]);
  };

  const removeMenu = (breakfastId) => {
    setBreakfasts((prev) => [
      ...prev.filter((breakfast) => {
        return breakfast._id !== breakfastId;
      }),
    ]);
  };

  const updateBreakfasts = (breakfastId, newBreakfast, extra = false) => {
    if (extra) {
      setExtra((prev) => [
        ...prev.map((breakfast) => {
          if (breakfast._id === breakfastId) {
            return newBreakfast;
          }
          return breakfast;
        }),
      ]);
      return;
    }
    setBreakfasts((prev) => [
      ...prev.map((breakfast) => {
        if (breakfast._id === breakfastId) {
          return newBreakfast;
        }
        return breakfast;
      }),
    ]);
  };

  const [breakfastId, setBreakfastId] = useState(null);
  const [optionToUpdate, setOptionToUpdate] = useState(null);
  const [optionToDeleteId, setOptionToDeleteId] = useState(null);
  const [breakfastToUpdate, setBreakfastToUpdate] = useState(null);
  const [breakfastToDelete, setBreakfastToDelete] = useState(null);
  const [isExtra, setIsExtra] = useState(false);

  function duplicateSection({ breakfastId, placeOn, optionId, isExtra }) {
    toast.promise(
      async () => {
        const updatedBreakfasts = await breakfastService.duplicateSection({ breakfastId, placeOn, optionId });
        if (isExtra) {
          setExtra((prev) => {
            return prev.map((breakfast) => (breakfast._id === updatedBreakfasts._id ? updatedBreakfasts : breakfast));
          });
        } else {
          setBreakfasts((prev) => {
            return prev.map((breakfast) => (breakfast._id === updatedBreakfasts._id ? updatedBreakfasts : breakfast));
          });
        }
      },
      {
        pending: "Duplication en cours...",
        success: "Duplication réussie",
        error: "Échec de duplication",
      },
      {
        autoClose: 1500,
        hideProgressBar: true,
      }
    );
  }

  async function toggleMenuVisibility({ breakfastId, value }) {
    toast.promise(
      async () => {
        const updated = await breakfastService.updateMenu({ breakfastId, hidden: value });
        updateBreakfasts(breakfastId, updated);
      },
      {
        pending: "Changement de visibilité...",
        success: !value ? "Menu visible" : "Menu invisible",
        error: "Échec de changement de visibilité",
      },
      {
        autoClose: 1500,
        hideProgressBar: true,
      }
    );
  }

  async function toggleSectionVisibility({ breakfastId, optionId, value, isExtra, optionData }) {
    toast.promise(
      async () => {
        const updated = await breakfastService.updateSection({ ...optionData, breakfastId, optionId: optionId, hidden: value });
        updateBreakfasts(breakfastId, updated, isExtra ?? false);
      },
      {
        pending: "Changement de visibilité...",
        success: !value ? "Section visible" : "Section invisible",
        error: "Échec de changement de visibilité",
      },
      {
        autoClose: 1500,
        hideProgressBar: true,
      }
    );
  }

  async function handleDragEnd(result) {
    if (!result.destination) return; // Dragged outside the list

    if (result.source.index === result.destination.index) {
      return; // Drag on the same position should not trigger an api call, so we cancel it
    }

    if (result.type === "MENU") {
      let previousMenu = Array.from(breakfasts);
      const newOrder = Array.from(breakfasts);
      const [movedItem] = newOrder.splice(result.source.index, 1);
      newOrder.splice(result.destination.index, 0, movedItem);

      // Update the 'order' property based on the new array order
      const updatedMenuItems = newOrder.map((item, index) => ({
        ...item,
        order: index,
      }));
      setBreakfasts(updatedMenuItems);

      toast.promise(
        async () => {
          try {
            await breakfastService.updateMenuOrder({
              updatedMenuItems,
            });
          } catch {
            // Reset the breakfasts menu to its previous value if the order has failed
            setBreakfasts(previousMenu);
            // Rethrow the error to trigger the 'error' state on the toast
            throw new Error();
          }
        },
        {
          pending: "Mis à jour en cours...",
          success: "Mis à jour réussie",
          error: "Échec de mis à jour",
        },
        {
          autoClose: 1500,
          hideProgressBar: true,
        }
      );

      return;
    }

    if (result.source.droppableId !== result.destination.droppableId) {
      return; // Drag outside its menu
    }

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;
    const sourceBreakfastId = result.source.droppableId;
    const type = result.type;

    const reorderedOptions = Array.from(type === "EXTRA" ? extra.find((b) => b._id === sourceBreakfastId).options : breakfasts.find((b) => b._id === sourceBreakfastId).options);

    const [draggedOption] = reorderedOptions.splice(sourceIndex, 1);
    reorderedOptions.splice(destinationIndex, 0, draggedOption);
    let previousExtra = Array.from(extra);
    let previousBreakfasts = Array.from(breakfasts);

    if (type === "EXTRA") {
      setExtra((prev) => prev.map((b) => (b._id === sourceBreakfastId ? { ...b, options: reorderedOptions } : b)));
    } else if (type === "BREAKFAST") {
      setBreakfasts((prev) => prev.map((b) => (b._id === sourceBreakfastId ? { ...b, options: reorderedOptions } : b)));
    }

    toast.promise(
      async () => {
        try {
          await breakfastService.updateOptionsOrder({
            breakfastId: sourceBreakfastId,
            updatedOptionsOrder: reorderedOptions.map((option) => option._id),
          });
        } catch {
          // Restore any breakfasts to its previous value if the order has failed
          if (type === "EXTRA") {
            setExtra(previousExtra);
          } else if (type === "BREAKFAST") {
            setBreakfasts(previousBreakfasts);
          }
          // Rethrow the error to trigger the 'error' state on the toast
          throw new Error();
        }
      },
      {
        pending: "Mis à jour en cours...",
        success: "Mis à jour réussie",
        error: "Échec de mis à jour",
      },
      {
        autoClose: 1500,
        hideProgressBar: true,
      }
    );
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <div className="mt-5 pt-5">
        <ExportButton />

        {/* This component is used for deleting both section and menu */}
        <DeleteModal
          show={showDeleteModal}
          onHide={() => {
            setIsExtra(false);
            setShowDeleteModal(false);
            setOptionToDeleteId(null);
            setBreakfastToDelete(null);
          }}
          isExtra={isExtra}
          updateBreakfasts={updateBreakfasts}
          breakfastId={breakfastId}
          removeMenu={removeMenu}
          optionToDeleteId={optionToDeleteId}
          breakfastToDelete={breakfastToDelete}
        />

        {/* This component is used for both adding and updating a menu */}
        <AddMenuModal
          show={showAddMenu}
          onHide={() => {
            setIsExtra(false);
            setShowAddMenu(false);
            setBreakfastToUpdate(null);
          }}
          isExtra={isExtra}
          breakfastToUpdate={breakfastToUpdate}
          addMenu={addMenu}
          updateMenu={updateBreakfasts}
        />

        {/* This component is used for both adding and updating a section */}
        <AddSectionModal
          show={showAddSection}
          onHide={() => {
            setIsExtra(false);
            setShowAddSection(false);
            setOptionToUpdate(null);
            setBreakfastId(null);
          }}
          isExtra={isExtra}
          optionToUpdate={optionToUpdate}
          breakfastId={breakfastId}
          updateBreakfasts={updateBreakfasts}
        />

        <EditableText placeholder="Texte selection menu" textKey="selectMenu" initialValue={getEditableText("selectMenu")?.value} />

        <Droppable type="MENU" droppableId="menu">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {breakfasts.map((breakfast, i) => (
                <Draggable key={breakfast._id} draggableId={breakfast._id} index={i}>
                  {(provided, snapshot) => (
                    <Menu
                      hideSectionPrice
                      provided={provided}
                      snapshot={snapshot}
                      breakfast={breakfast}
                      droppableType="BREAKFAST"
                      onToggleVisibility={(value) => {
                        toggleMenuVisibility({ breakfastId: breakfast._id, value });
                      }}
                      onEdit={() => {
                        setBreakfastToUpdate(breakfast);
                        setShowAddMenu(true);
                      }}
                      onDelete={() => {
                        setBreakfastToDelete(breakfast);
                        setShowDeleteModal(true);
                      }}
                      onAdd={() => {
                        setBreakfastId(breakfast._id);
                        setShowAddSection(true);
                      }}
                      sectionActions={{
                        onToggleVisibility: (option, value) => {
                          toggleSectionVisibility({ breakfastId: breakfast._id, optionId: option._id, value });
                        },
                        onDuplicate: (option, index) => {
                          duplicateSection({
                            breakfastId: breakfast._id,
                            placeOn: index + 1,
                            optionId: option._id,
                            isExtra: false,
                          });
                        },
                        onEdit: (option) => {
                          setBreakfastId(breakfast._id);
                          setOptionToUpdate(option);
                          setShowAddSection(true);
                        },
                        onDelete: (option) => {
                          setBreakfastId(breakfast._id);
                          setOptionToDeleteId(option._id);
                          setShowDeleteModal(true);
                        },
                      }}
                    />
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>

        <div className="w-100 mt-5">
          <Button onClick={() => setShowAddMenu(true)} colorScheme="gray" width={"100%"} paddingY={20} variant="outline">
            + Ajouter une Menu
          </Button>
        </div>

        <EditableText placeholder="Texte selection extra" textKey="addExtra" initialValue={getEditableText("addExtra")?.value} />

        {(() => {
          const breakfast = extra[0];

          if (!breakfast) {
            return null;
          }

          return (
            <Menu
              disableDrag
              disableDelete
              disableVisibilityControl
              hidePrice
              droppableType="EXTRA"
              breakfast={breakfast}
              onEdit={() => {
                setBreakfastToUpdate(breakfast);
                setIsExtra(true);
                setShowAddMenu(true);
              }}
              onAdd={() => {
                setIsExtra(true);
                setBreakfastId(breakfast._id);
                setShowAddSection(true);
              }}
              sectionActions={{
                onToggleVisibility: (option, value) => {
                  toggleSectionVisibility({
                    breakfastId: breakfast._id,
                    optionId: option._id,
                    value,
                    optionData: option,
                    isExtra: true,
                  });
                },
                onDuplicate: (option, index) => {
                  duplicateSection({
                    breakfastId: breakfast._id,
                    placeOn: index + 1,
                    optionId: option._id,
                    isExtra: true,
                  });
                },
                onEdit: (option) => {
                  setIsExtra(true);
                  setBreakfastId(breakfast._id);
                  setOptionToUpdate(option);
                  setShowAddSection(true);
                },
                onDelete: (option) => {
                  setIsExtra(true);
                  setBreakfastId(breakfast._id);
                  setOptionToDeleteId(option._id);
                  setShowDeleteModal(true);
                },
              }}
            />
          );
        })()}
      </div>
    </DragDropContext>
  );
}

export default Breakfast;
