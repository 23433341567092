import axios from "./axios";
//getAvis
export const getAvis = async () => {
  const response = await axios.get("/avis/getAvis");
  return response.data;
};

export const suppAvis = async (id) => {
  const response = await axios.delete("/avis/" + id);
  return response.data;
};
