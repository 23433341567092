import { Flex,  Table,  Tbody,  Td,  Text,  Th,  Thead,  Tr,  useColorModeValue,} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { BsTrash, BsPencil } from "react-icons/bs";
import { Link } from "react-router-dom";
import "./index.css";
import Card from "components/card/Card";
import {  getChambre,  supprimer,  getHotelType,  getAllCategorie,  unBlockRoom,  blockRoom,} from "../../../../api/Chambre";
import { Modal } from "react-bootstrap";
import BlockModal from "./BlockModal";
import axios from "axios";
import { useHistory } from "react-router-dom";
import ModifyModal from "./ModifyModal";

// Assets
export default function ColumnsTable(props) {
  const [chambre, setListChambre] = useState([]);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const [blockRoomID, setBlockRoomID] = useState();
  const history = useHistory();
  const [roomToModify , setRoomToModify ] = useState('');
  const [showModalToModifyRoom, setShowModalToModifyRoom] = useState(false);  
  const [ajoutChambre, setAjoutChambre] = useState(false);
  const [hotelType, setHotelType] = useState([]);
  const [categorie, setCategorie] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showBlockModal, setShowBlockModal] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");

  function confirmation(id, intitule) {
    if (
      window.confirm("Voulez-vous vraiment effacer la chambre: " + intitule)
    ) {
      supprimer(id);
      const newChambres = chambre.filter((el) => el._id !== id);
      setListChambre(newChambres);
    } else {
    }
  } 

  const liste = () => {
    const choixListe = document.getElementById("choixListe")?.value;
    setSelectedValue(choixListe);
    if (selectedValue === "chambre") {
      window.location.href = "/#/admin/chambre";
    } else if (selectedValue === "location") {
      window.location.href = "/#/admin/voiture";
    }
  };

  const Enregistrer = (e) => {
    e.preventDefault(); 
    const form = document.getElementById("formChambre");
    const formData = new FormData(form);
  
    axios.post(process.env.REACT_APP_BACK_URL + "/hotel/creerChambre", formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
    .then(response => {
      history.go(0)
    })
    .catch(error => {
      console.error(error);
    });
  };

  function handleRoomBlockClick(roomID) {
    setBlockRoomID(roomID);
    setShowBlockModal(true);
  }

  async function onBlockRoom(params) {
    const { idChambre, isBlocked, permanent, dateStart, dateEnd } = params;
    setLoading(true);
    try {
      const blockedRoom = await blockRoom(
        idChambre,
        isBlocked,
        permanent,
        dateStart,
        dateEnd
      );

      // Replaced the old room data to the new data
      const newRoomList = chambre.map((room) => {
        if (room._id === blockedRoom._id) {
          return blockedRoom;
        }
        return room;
      });
      setListChambre(newRoomList);
    } catch {
    } finally {
      setLoading(false);
    }
  }

  async function handleUnblockRoom(roomID) {
    setLoading(true);
    try {
      const room = await unBlockRoom(roomID);

      // Replaced the old room data to the new data
      const newRoomList = chambre.map((r) => {
        if (room._id === r._id) {
          return room;
        }
        return r;
      });
      setListChambre(newRoomList);
    } catch {
    } finally {
      setLoading(false);
    }
  }
  
  const handleEditRoom = (uneChambre)=>{
    setRoomToModify(uneChambre)
  }

  useEffect(() => {
    getChambre().then((listeChambre) => {
      setListChambre(listeChambre);
    });
  }, []);

  useEffect(() => {
    liste();
  }, [selectedValue]);

  useEffect(()=>{
    if (roomToModify !== '')
    {
      setShowModalToModifyRoom(true);
    }
    else
      setShowModalToModifyRoom(false);
  },[roomToModify]);
  
  useEffect(() => {
    getHotelType().then((hotelType) => {
      setHotelType(hotelType);
    });
    getAllCategorie().then((listeCategorie) => {
      setCategorie(listeCategorie)
    });
  }, []);

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <div className="col-12 d-flex align-items-baseline">
        <div className="submit-area mb-2 col-6">
          <select
            name="choixListe"
            id="choixListe"
            onClick={() => liste()}
            className="selectChoix my-3 p-2"
            style={{ border: "1px solid darkGray" }}
          >
            <option value="" hidden>
              Chambre / Location
            </option>
            <option value="chambre" className="">
              Liste des chambres
            </option>
            <option value="location" className="">
              Liste des voitures
            </option>
          </select>
        </div>
      </div>
      <Flex px="25px" justify="space-between" mb="10px" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          Liste des Chambres
        </Text>
        <button
          onClick={() => {
            setAjoutChambre(true);
          }}
          className="btn text-light rounded-0 border-0"
          style={{ background: '#2ecc71' }}
        >
          Ajouter une nouvelle chambre
        </button>

      </Flex>
      <BlockModal
        show={showBlockModal}
        onHide={() => {
          setShowBlockModal(false);
        }}
        onBlockRoom={onBlockRoom}
        blockroomid={blockRoomID}
      />

      <ModifyModal 
        show={showModalToModifyRoom}
        handleHide={()=>setRoomToModify('')} 
        roomContent={roomToModify}
        hotelType={hotelType}
        categorie={categorie}/>

      <Modal
        show={ajoutChambre}
        onHide={() => setAjoutChambre(false)}
        scrollable={true}
        backdrop="static"
        keyboard={false}
        centered
        className="border-0"
        size="md"
      >
        <Modal.Header closeButton onClick={() => setAjoutChambre(false)}>
          <div className="w-100 d-flex justify-content-center">
            <p className="titreModal">
              Ajout d'une nouvelle chambre
            </p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <form
            id="formChambre"
            method="post"
            encType="multipart/form-data"
            onSubmit={(e) => {
              Enregistrer(e);
              setAjoutChambre(false);
            }}
          >
            <div className="form-group row">
              <div className="form-group col-12 col-md-12 col-lg-12 d-flex flex-wrap justify-content-around">
                <div className="col-12 col-md-12 col-lg-12 d-flex justify-content-evenly my-2">
                  <label htmlFor="" className="col-3 col-form-label">
                    Intitule :{" "}
                  </label>
                  <div className="form-field col-8 col-md-8 col-lg-8">
                    <input
                      id="intitule"
                      type="text"
                      name="intitule"
                      className="form-control py-2"
                      placeholder="Numéro de la chambre"
                      required
                    />
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-12 d-flex justify-content-evenly my-2">
                  <label htmlFor="" className="col-3 col-form-label">
                    Type de l'hôtel :{" "}
                  </label>
                  <div className="form-field col-8 col-md-8 col-lg-8">
                    <select
                      name="idHotelType"
                      id="idHotelType"
                      className="form-control"
                      required
                    >
                      {hotelType.map((uneHotelType, index) => {
                        return (
                          <option value={uneHotelType._id} key={index}>
                            {uneHotelType.type}
                          </option>
                        );
                      })}
                      ;
                    </select>
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-12 d-flex justify-content-evenly my-2">
                  <label htmlFor="" className="col-3 col-form-label">
                    Catégorie :{" "}
                  </label>
                  <div className="form-field col-8 col-md-8 col-lg-8">
                    <select
                      name="idCategorie"
                      id="idCategorie"
                      className="form-control"
                      required
                    >
                      {categorie.map((uneCategorie, index) => {
                        return (
                          <option value={uneCategorie._id} key={index}>
                            {uneCategorie.intitule}
                          </option>
                        );
                      })}
                      ;
                    </select>
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-12 d-flex justify-content-evenly my-2">
                  <label htmlFor="" className="col-3 col-form-label">
                    Image :{" "}
                  </label>
                  <div className="form-field col-8 col-md-8 col-lg-8">
                    <input
                      id="chambreImage"
                      className="form-control py-2"
                      type="file"
                      name="chambreImage"
                      required
                    />
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-12 d-flex justify-content-evenly my-2">
                  <label htmlFor="" className="col-6 col-form-label">
                    Prix nuitée:{" "}
                  </label>
                  <div className="form-field col-5 col-md-5 col-lg-5">
                    <input
                      id="prix"
                      className="form-control py-2"
                      type="number"
                      min="0"
                      name="prix"
                      placeholder="Prix"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="submit-area mt-4 col-12 col-md-12 col-lg-12 d-flex justify-content-end">
              <button
                type="submit"
                className="btn "
                style={{ background :'#2ecc71', borderRadius: '3px', color: 'white' }}
              >
                Ajouter
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <Table variant="striped" color="gray.500" mb="24px">
        <Thead>
          <Tr>
            <Th pe="10px" borderColor={borderColor}>
              Intitule
              <Flex
                justify="space-between"
                align="center"
                fontSize={{ sm: "10px", lg: "12px" }}
                className="text-danger"
              >
                {" "}
              </Flex>
            </Th>
            <Th pe="10px" borderColor={borderColor}>
              Type de la chambre
              <Flex
                justify="space-between"
                align="center"
                fontSize={{ sm: "10px", lg: "12px" }}
                color="gray.400"
              >
                {" "}
              </Flex>
            </Th>
            <Th pe="10px" borderColor={borderColor}>
              Catégorie
              <Flex
                justify="space-between"
                align="center"
                fontSize={{ sm: "10px", lg: "12px" }}
                color="gray.400"
              >
                {" "}
              </Flex>
            </Th>
            <Th pe="10px" borderColor={borderColor}>
              Prix par nuité
              <Flex
                justify="space-between"
                align="center"
                fontSize={{ sm: "10px", lg: "12px" }}
                color="gray.400"
              >
                {" "}
              </Flex>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {chambre.map((uneChambre) => {
            return (
              <Tr key={uneChambre._id}>
                <Td
                  fontSize={{ sm: "14px" }}
                  maxH="30px !important"
                  py="8px"
                  minW={{ sm: "150px", md: "200px", lg: "auto" }}
                  borderColor="transparent"
                >
                  <Text color={textColor} fontSize="sm" fontWeight="700">
                    {" "}
                    {uneChambre.intitule}{" "}
                  </Text>
                </Td>
                <Td
                  fontSize={{ sm: "14px" }}
                  maxH="30px !important"
                  py="8px"
                  minW={{ sm: "150px", md: "200px", lg: "auto" }}
                  borderColor="transparent"
                >
                  <Text color={textColor} fontSize="sm" fontWeight="700">
                    {" "}
                    {uneChambre.idHotelType.type}{" "}
                  </Text>
                </Td>
                <Td
                  fontSize={{ sm: "14px" }}
                  maxH="30px !important"
                  py="8px"
                  minW={{ sm: "150px", md: "200px", lg: "auto" }}
                  borderColor="transparent"
                >
                  <Text color={textColor} fontSize="sm" fontWeight="700">
                    {" "}
                    {uneChambre.idCategorie.intitule}{" "}
                  </Text>
                </Td>
                <Td
                  fontSize={{ sm: "14px" }}
                  maxH="30px !important"
                  py="8px"
                  minW={{ sm: "150px", md: "200px", lg: "auto" }}
                  borderColor="transparent"
                >
                  <Text color={textColor} fontSize="sm" fontWeight="700">
                    {" "}
                    {uneChambre.prix.toLocaleString()} Ar{" "}
                  </Text>
                </Td>
                <Td
                  fontSize={{ sm: "10px" }}
                  maxH="20px !important"
                  py="4px"
                  minW={{ sm: "100px", md: "100px", lg: "auto" }}
                  borderColor="transparent"
                >
                  <Link
                    onClick={() => {
                      const id = uneChambre._id;
                      confirmation(id, uneChambre.intitule);
                    }}
                    to="/admin/chambre"
                    className="theme-btn bValider"
                  >
                    {" "}
                    <BsTrash className="font" />
                  </Link>
                </Td>
                <Td
                  fontSize={{ sm: "10px" }}
                  maxH="20px !important"
                  py="4px"
                  minW={{ sm: "100px", md: "100px", lg: "auto" }}
                  borderColor="transparent"
                >
                  <div
                    onClick={()=>handleEditRoom(uneChambre)}
                    style={{ cursor: 'pointer' }}
                    // to={`/admin/modifier/${uneChambre._id}`}
                    params={{ testvalue: "hello" }}
                  >
                    <BsPencil className="font" />
                  </div>
                </Td>
                <Td>
                  {!uneChambre.isBlocked ? (
                    <button
                      className="btn btn-danger"
                      style={{ borderRadius: '3px' }}
                      onClick={() => {
                        handleRoomBlockClick(uneChambre._id);
                      }}
                    >
                      Bloquer
                    </button>
                  ) : (
                    <button
                      className="btn btn-danger"
                      style={{ borderRadius: '3px' }}
                      onClick={() => {
                        handleUnblockRoom(uneChambre._id);
                      }}
                    >
                      Débloquer
                    </button>
                  )}
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Card>
  );
}
