import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";

function Edit(allProps) {
  const { editLoading, selectedInfos, onInfosEdited, ...props } = allProps;
  const [value, setValue] = useState("");

  useEffect(() => {
    if (selectedInfos.value) {
      setValue(selectedInfos.value);
    }
  }, [selectedInfos]);

  function onEditClicked() {
    onInfosEdited({
      ...selectedInfos,
      value,
    });
  }

  return (
    <Form>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {selectedInfos?.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Valeur</Form.Label>
            <Form.Control
              type="text"
              onChange={(e) => {
                setValue(e.target.value);
              }}
              value={value}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide} variant={"outline-secondary"}>
            Annuler
          </Button>
          <Button
            onClick={() => {
              onEditClicked();
            }}
            variant={"danger"}
            disabled={editLoading}
            style={{
              opacity: editLoading ? 0.6 : 1,
            }}
          >
            {editLoading ? "En cours..." : "Sauvegarder"}
          </Button>
        </Modal.Footer>
      </Modal>
    </Form>
  );
}

export default Edit;
