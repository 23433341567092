import React from "react";

import { GiLockedDoor } from "react-icons/gi";
import { FiLogOut } from "react-icons/fi";
import { TbCoinOff } from "react-icons/tb";
import {
  FaHome,
  FaCalendarAlt,
  FaCalendarCheck,
  FaCoins,
  FaWpforms,
  FaLock,
  FaMugHot,
  FaPencilAlt,
  FaUserEdit,
} from "react-icons/fa";
import { GrDocumentText } from "react-icons/gr";
import { IoRestaurant } from "react-icons/io5";
import { PiFilesFill } from "react-icons/pi";

// Admin Imports
import NonPayer from "views/admin/reservation/reservationNonPayer";
import Occupe from "views/admin/reservation";
import Reserver from "views/admin/formulaire/reservation.jsx";
import Bloquer from "views/admin/formulaire/bloquer.jsx";
import Chambre from "views/admin/chambre";
import Voiture from "views/admin/voiture";
import Calendar from "views/admin/calendrier";
import Paiement from "views/admin/paiement";
import Bloque from "views/admin/reservation/chambreBloque";
import PetitDej from "views/admin/petitDej";
import Blog from "views/admin/blog";
import Location from "views/admin/location";
import Avis from "views/admin/avis";
import Logout from "views/auth/signIn";
import "./index.css";
import SiteInsights from "views/admin/siteInsights";
import Breakfast from "views/admin/breakfast"

const routes = [
  {
    name: "Chambre et voiture",
    layout: "/admin",
    path: "/chambre",
    icon: <FaHome className="iconMenu" />,
    component: Chambre,
  },
  // {
  //   name: "Calendrier",
  //   layout: "/admin",
  //   path: "/calendrier",
  //   icon: <FaCalendarAlt className="iconMenu" />,
  //   component: Calendar,
  // },
  {
    name: "Liste des réservations",
    layout: "/admin",
    path: "/occupe",
    icon: <FaCalendarCheck className="iconMenu" />,
    component: Occupe,
  },
  // {
  //   name: "Liste des bloquées",
  //   layout: "/admin",
  //   path: "/bloque",
  //   icon: <GiLockedDoor className="iconMenu" />,
  //   component: Bloque,
  // },
  // {
  //   name: "Liste des paiements",
  //   layout: "/admin",
  //   icon: <FaCoins className="iconMenu" />,
  //   path: "/paiement",
  //   component: Paiement,
  // },
  // {
  //   name: "Non payée",
  //   layout: "/admin",
  //   path: "/nonPayer",
  //   icon: <TbCoinOff className="iconMenu" />,
  //   component: NonPayer,
  // },

  // {
  //   name: "Formulaire de réservation",
  //   layout: "/admin",
  //   path: "/reserver",
  //   icon: <FaWpforms className="iconMenu" />,
  //   component: Reserver,
  // },
  // {
  //   name: "Petit déjeuner",
  //   layout: "/admin",
  //   path: "/petitDej",
  //   icon: <FaMugHot className="iconMenu" />,
  //   component: PetitDej,
  // },
  // {
  //   name: "Blog",
  //   layout: "/admin",
  //   path: "/blog",
  //   icon: <FaPencilAlt className="iconMenu" />,
  //   component: Blog,
  // },
  {
    name: "Avis client",
    layout: "/admin",
    path: "/avis",
    icon: <FaUserEdit className="iconMenu" />,
    component: Avis,
  },
  {
    name: "Petit déjeuner",
    layout: "/admin",
    path: "/breakfasts",
    icon: <IoRestaurant className="iconMenu"/>,
    component: Breakfast,
  },

  {
    name: "Autres informations",
    layout: "/admin",
    path: "/insight",
    icon: <PiFilesFill className="iconMenu"/>,
    component: SiteInsights,
  },
  {
    name: "Déconnexion",
    layout: "/admin",
    path: "/log-out",
    icon: <FiLogOut className="iconMenu" />,
    component: Logout,
  },
  {
    name: "",
    layout: "/admin",
    path: "/location",
    // icon: <FaCarSide className="iconMenu"/>,
    component: Location,
  },
  {
    name: "",
    layout: "/admin",
    path: "/voiture",
    component: Voiture,
  },
];

export default routes;
