import {
    Link
  } from "react-router-dom";
  import {
    Box,
    SimpleGrid,
  } from "@chakra-ui/react";
  import { getChambre } from "../../../api/Chambre";
  import React, { useEffect, useState } from "react";
import { bloquerChambre } from '../../../api/Reservation';

  export default function UserReports() {
    const [chambre, setListChambre] = useState([]);
    useEffect(() => {
      if (chambre.length === 0 ) {
        getChambre().then((chambre) => {
          setListChambre(chambre);
        });
      }
    }, [chambre.length]);
    const Reservation = (e) => {
      const idChambre = (document.getElementById("chambre")).value
      const start = (document.getElementById("start")).value
      const end = (document.getElementById("end")).value
      bloquerChambre(idChambre, start, end)
    } 
    var curr = new Date().toISOString().substring(0,10);
     
  const [selectedValue, setSelectedValue] = useState('')
  useEffect(()=>{
    liste()
  }, [selectedValue])
  const liste = () => {
    const choixListe = document.getElementById("choixListe")?.value
    setSelectedValue(choixListe)
    if(selectedValue === "chambre"){
      window.location.href = "/#/admin/bloquerChambre"
    }
    else if(selectedValue === "location"){
      window.location.href = "/#/admin/blocageVoiture"
    }
  }
    return (
  
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        
      <select name="choixListe" id="choixListe" onClick={()=>liste()} className="text-light bg-danger selectChoix my-3 p-2">
        <option value="" hidden>Chambre / Location</option>
        <option value="chambre" className='text-light'>Formulaire de blocage de chambre</option>
        <option value="location" className='text-light'>Formulaire de blocage de voiture</option>
      </select>
        <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mb='20px'>
          <form  className="contact-validation-active" >
            <div className="row">
              <div className="col col-lg-4 col-12">
                <div className="form-field">
                  <label>
                    Start :
                    <input id="start" className="form-control" defaultValue={curr} type="date" name="start"/>
                  </label>
                </div>
              </div>
              <div className="col col-lg-4 col-12">
                <div className="form-field">
                  <label>
                    End :
                    <input id="end" className="form-control" defaultValue={curr} type="date" name="end" />
                  </label>
                </div>
              </div>
              <div className="col col-lg-4 col-12">
                <div className="form-field">
                  <label>
                    Chambre :
                    <select name="chambre" id="chambre" className="form-control">
                      {chambre.map((uneChambre, index) => {
                          return <option value={uneChambre._id} key={index}>{uneChambre.intitule +" | "+ uneChambre.prix.toLocaleString()+" Ar"}</option>
                      })};
                    </select>
                  </label>
                </div>
              </div>
            </div>
  
            <div className="submit-area my-2">
              <Link onClick={(e) => {
                // ClickHandler()
                Reservation(e);
              }} to="/admin/bloque" className="btn btn-danger mt-2">Bloquer cette voiture</Link>
            </div>
          </form >
        </SimpleGrid>
      </Box>
    );
  }