// Chakra imports
import { Box, SimpleGrid, Text, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { getLocationId } from "../../../../api/location"
export default function GeneralInformation(props) {
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const { id } = useParams()
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const bg = useColorModeValue("white", "navy.700");
  const [location, setLocation] = useState([])
  const [montant, setMontant] = useState(0)
  useEffect(() => {
    getLocationId(id).then((uneLocation) => {
      setLocation(uneLocation)
        setMontant(uneLocation?.jour * uneLocation?.prix)
    })
  }, [])
  const formateDate = (date) => {
    return new Date(date).toLocaleDateString()
  }
  return (
    <Card mb={{ base: "0px", "2xl": "60px" }} >
      <Text
        color={textColorPrimary}
        fontWeight='bold'
        fontSize='2xl'
        mt='10px'
        mb='4px'>
        Information du client
      </Text>
      <SimpleGrid columns='3' gap='40px' me='20px'>
        <Card bg={bg} boxShadow={cardShadow}>
          <Box>
            <Text fontWeight='500' color={textColorSecondary} fontSize='sm'>
              Nom et prénom
            </Text>
            <Text color={textColorPrimary} fontWeight='500' fontSize='md'>
              {location.client?.prenom} <span className="uppercase">{location.client?.nom}</span>
            </Text>
          </Box>
        </Card>

        <Card bg={bg} boxShadow={cardShadow}>
          <Box>
            <Text fontWeight='500' color={textColorSecondary} fontSize='sm'>
              Mail
            </Text>
            <Text color={textColorPrimary} fontWeight='500' fontSize='md'>
              {location.client?.email}
            </Text>
          </Box>
        </Card>
        <Card bg={bg} boxShadow={cardShadow}>
          <Box>
            <Text fontWeight='500' color={textColorSecondary} fontSize='sm'>
              Contact
            </Text>
            <Text color={textColorPrimary} fontWeight='500' fontSize='md'>
              {location.client?.numero}
            </Text>
          </Box>
        </Card>
        <Card bg={bg} boxShadow={cardShadow}>
          <Box>
            <Text fontWeight='500' color={textColorSecondary} fontSize='sm'>
              Marque de la voiture
            </Text>
            <Text color={textColorPrimary} fontWeight='500' fontSize='md'>
              {location?.marque}
            </Text>
          </Box>
        </Card>
        <Card bg={bg} boxShadow={cardShadow}>
          <Box>
            <Text fontWeight='500' color={textColorSecondary} fontSize='sm'>
              Check In
            </Text>
            <Text color={textColorPrimary} fontWeight='500' fontSize='md'>
              {formateDate(location?.start)}
            </Text>
          </Box>
        </Card>
        <Card bg={bg} boxShadow={cardShadow}>
          <Box>
            <Text fontWeight='500' color={textColorSecondary} fontSize='sm'>
              Check Out
            </Text>
            <Text color={textColorPrimary} fontWeight='500' fontSize='md'>
              {formateDate(location?.end)}
            </Text>
          </Box>
        </Card>
        <Card bg={bg} boxShadow={cardShadow}>
          <Box>
            <Text fontWeight='500' color={textColorSecondary} fontSize='sm'>
              Nombre de jour
            </Text>
            <Text color={textColorPrimary} fontWeight='500' fontSize='md'>
              {location?.jour}
            </Text>
          </Box>
        </Card>
        <Card bg={bg} boxShadow={cardShadow}>
          <Box>
            <Text fontWeight='500' color={textColorSecondary} fontSize='sm'>
              Prix de location par jour
            </Text>
            <Text color={textColorPrimary} fontWeight='500' fontSize='md'>
              {(location?.prix)} Ar
            </Text>
          </Box>
        </Card>
        <Card bg={bg} boxShadow={cardShadow}>
          <Box>
            <Text fontWeight='500' color={textColorSecondary} fontSize='sm'>
              Montant à payer
            </Text>
            <Text color={textColorPrimary} fontWeight='500' fontSize='md'>
              {(montant.toLocaleString()).toString()} Ar
            </Text>
          </Box>
        </Card>
      </SimpleGrid>
    </Card>
  );
}
