import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "@chakra-ui/react";
import axios from 'axios';
import { useHistory } from 'react-router-dom';

export default function ConfirmDeleteModal({ show, onHide }) {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const deleteAll = async () => {
    setLoading(true);
    try {
        await axios.delete(`${process.env.REACT_APP_BACK_URL}/breakfasts/delete-all-breakfasts`);
        setLoading(false);
        history.go(0); 
    } catch (error) {
        console.log(error.response.status);
        setLoading(false);
    }
  } 

  return (
    <>
      <Modal show={show} onHide={onHide} centered backdrop="static">
        <Modal.Body >
          <div className="pt-3">
            <h1>Voulez-vous supprimer toutes les données du petit déjeuner ?</h1>
            <div className="mt-4 w-100 d-flex justify-content-end">
              <Button type="button" marginX={3} colorScheme="blue" variant="ghost" onClick={onHide}>
                Annuler
              </Button>
              <Button isLoading={loading} type="submit" colorScheme="red" variant="solid" onClick={deleteAll}>
                Supprimer
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
