import React, { useState } from "react";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button } from "@chakra-ui/react";
import { Table, Thead, Tbody, Tr, Th, Td, TableContainer } from "@chakra-ui/react";
import moment from "moment";

const ViewModal = ({ isOpen, onClose, carLocation, onDelete }) => {
  const [isLoading, setIsLoading] = useState(false);

  if (!carLocation) {
    return;
  }

  const { firstName, lastName, email, phoneNumber } = carLocation.client;
  const { marque } = carLocation.vehicle;
  const { startDate, endDate, prix: price, destination, reason } = carLocation;

  const tableRows = {
    Nom: firstName,
    Prénom: lastName,
    Email: email,
    Telephone: phoneNumber,
    Marque: marque,
    "Debut de location": moment(startDate).format("DD MMMM YYYY"),
    "Fin de location": moment(endDate).format("DD MMMM YYYY"),
    Reason: reason,
    Destination: destination,
    "Prix total": `AR ${price}`,
  };

  async function handleDelete() {
    setIsLoading(true);
    try {
        await onDelete(carLocation._id);
        onClose();
    } catch {
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent maxW="45rem">
          <ModalHeader textAlign={"center"}>
            <h1>Location Voiture</h1>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <TableContainer>
              <Table variant="simple" size="sm">
                <Thead>
                  <Tr>
                    <Th></Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {Object.keys(tableRows).map((key) => {
                    const value = tableRows[key];
                    return (
                      <Tr key={key}>
                        <Th>{key}</Th>
                        <Td textAlign={"right"} whiteSpace={"break-spaces"}>
                          {value}
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={3} variant="ghost" onClick={handleDelete} isLoading={isLoading}>
              Supprimer
            </Button>
            <Button onClick={onClose}>Fermer</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ViewModal;
