import axiosInstance from "./axios";
import moment from "moment";

// GET_Paiement-entre-2-date
export const getPaiementLocation = async (start, end, typePaie) => {
  return await axiosInstance
    .get("/paiementLocation/getPaiementLocation", {
      params: {
        start: moment(start).toISOString(),
        end: moment(end).toISOString(),
        typePaie: typePaie,
      },
    })
    .then((listePaiement) => {
      return listePaiement.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

//Payer Location
export const payerLocation = async (
  idLocation,
  idTypePaie,
  montant,
  remise,
  date
) => {
  await axiosInstance
    .post("/paiementLocation/PayerLocation", {
      idLocation,
      idTypePaie,
      montant,
      remise,
      date,
    })
    .then((res) => {
      alert(res.data.message);
    })
    .catch((err) => {
      console.log(err);
    });
};
export const getPaiementLocationId = async (id) => {
  return await axiosInstance
    .get(`/paiementLocation/getPaiementLocation/` + id, {})
    .then((location) => {
      return location.data;
    })
    .catch((err) => {
      console.log(err);
    });
};
