
export const formateDate = (date) => {
    const newDate = date.split('-')
    return newDate[2] + "/" + newDate[1] + "/" + newDate[0]
}
export const dateSendBack = (date) => {
    let [startDay, startMonth, startYear] = date.split('/')
    let start_date = new Date(Date.UTC(startYear, startMonth - 1, startDay))
    return start_date.toISOString()
}
