import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import { blockRoom } from "api/Chambre";

export default function BlockModal({ onBlockRoom, ...props }) {
  const [blockingType, setBlockingType] = useState("permanent");
  const [showDateRange, setShowDateRange] = useState(false);
  const [dateStart, setDateStart] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);

  useEffect(() => {
    if (blockingType === "range") {
      setShowDateRange(true);
    } else {
      setShowDateRange(false);
    }
  }, [blockingType]);

  const handleBlockRoomClick = async (idChambre) => {
    const permanent = blockingType === "permanent" ? true : false;
    await onBlockRoom({
      idChambre,
      isBlocked: true,
      permanent,
      dateStart,
      dateEnd,
    });
    props.onHide();
  };

  return (
    <Modal
      {...props}
      onHide={() => {
        setShowDateRange(false);
        setBlockingType("permanent");
        props.onHide();
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Bloquer une chambre
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <div className="row my-3">
            <div className="col-6">
              <label htmlFor="select-blocking-type">Type de bloquage</label>
              <Form.Select
                aria-label="Room blocking type"
                id="select-blocking-type"
                onChange={(e) => {
                  setBlockingType(e.target.value);
                }}
              >
                <option value="permanent">Permanent</option>
                <option value="range">Entre deux date</option>
              </Form.Select>
            </div>
          </div>
          {showDateRange ? (
            <div className="row">
              <div className="col">
                <label>Date de début</label>
                <Form.Control
                  type="date"
                  min={new Date().toISOString().split("T")[0]}
                  onChange={(e) => {
                    setDateStart(e.target.value);
                  }}
                />
              </div>
              <div className="col">
                <label>Date de fin </label>
                <Form.Control
                  type="date"
                  min={new Date().toISOString().split("T")[0]}
                  onChange={(e) => {
                    setDateEnd(e.target.value);
                  }}
                />
              </div>
            </div>
          ) : null}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} variant="outline-secondary">
          Annuler
        </Button>
        <Button
          onClick={() => handleBlockRoomClick(props.blockroomid)}
          variant="danger"
        >
          Bloquer
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
